<template>
  <div>
    <el-header>
      <navtop></navtop>
    </el-header>
    <div class="userStyle-purchase">
      <!-- -->
      <div style="position: relative">
        <div style="position: absolute; top: 0px; z-index: 9999">
          <img src="@/assets/imgs/xszk.png" alt="" />
        </div>
      </div>
      <!-- banner -->
      <div>
        <!-- <div style="position: absolute;top: 0px;z-index: 9999;"> -->
        <img src="@/assets/imgs/banner.png" alt="" width="100%" />
        <!-- </div> -->
      </div>
      <div style="margin-top: -140px; position: relative">
        <!-- <div style="position: absolute;top: 10px;right: -2px;" v-show="voiceVip == false">
                <img src="@/assets/imgs/yuyinbeijing.png" alt="" width="576px">
            </div>
            <div style="position: absolute;" v-show="voiceVip == false"> -->
        <!-- <img src="@/assets/imgs/shuzirenhuiyuan.png" alt="" width="100%"> -->
        <!-- </div>
            <div style="position: absolute;top: 10px;left: -2px;" v-show="voiceVip == true">
                <img src="@/assets/imgs/yuyinbeijing1.png" alt="" width="576px">
            </div> -->
        <!-- <div style="position: absolute;" v-show="voiceVip == true"> -->
        <!-- <img src="@/assets/imgs/shuzirenhuiyuan1.png" alt="" width="100%"> -->
        <!-- </div> -->
        <div
          style="
            position: absolute;
            left: 38%;
            width: 300px;
            height: 60px;
            cursor: pointer;
          "
          @click="getVoice(false, 1)"
          v-show="voiceVip == false"
        >
          <div
            style="
              color: #ffffff;
              font-size: 20px;
              font-weight: 600;
              margin-top: 20px;
              margin-left: 25px;
            "
          >
            数字人直播会员
          </div>
          <div
            style="
              width: 40px;
              height: 4px;
              background-color: #fff;
              border-radius: 2px;
              margin-top: 10px;
              margin-left: 70px;
            "
          ></div>
        </div>
        <div
          style="
            position: absolute;
            right: 30%;
            top: 10px;
            width: 300px;
            height: 50px;
            cursor: pointer;
          "
          @click="getVoice(true, 2)"
          v-show="voiceVip == false"
        >
          <div style="color: #f1ebff; font-size: 16px; margin-top: 10px">
            数字语音直播会员
          </div>
          <!-- <div style="margin-top: -20px;margin-left: 460px;">
                    <img src="@/assets/imgs/weixiala.png" alt="" width="20px">
                </div> -->
        </div>
        <div
          style="
            position: absolute;
            left: 38%;
            width: 300px;
            height: 60px;
            cursor: pointer;
          "
          @click="getVoice(false, 1)"
          v-show="voiceVip == true"
        >
          <div
            style="color: #f1ebff; font-size: 16px; margin-top: 20px; margin-left: 35px"
          >
            数字人直播会员
          </div>
          <!-- <div style="margin-top: -20px;margin-left: 490px;">
                    <img src="@/assets/imgs/weixiala.png" alt="" width="20px">
                </div> -->
        </div>
        <div
          style="
            position: absolute;
            right: 32%;
            top: 10px;
            width: 300px;
            height: 50px;
            cursor: pointer;
          "
          @click="getVoice(true, 2)"
          v-show="voiceVip == true"
        >
          <div
            style="
              color: #ffffff;
              font-size: 20px;
              font-weight: 600;
              margin-top: 10px;
              margin-left: 25px;
            "
          >
            数字语音直播会员
          </div>
          <div
            style="
              width: 40px;
              height: 4px;
              background-color: #fff;
              border-radius: 2px;
              margin-top: 10px;
              margin-left: 80px;
            "
          ></div>
        </div>
      </div>
      <div style="width: 100%; display: flex; justify-content: center">
        <div class="qieStyle" v-show="isShow == false">
          <div style="cursor: pointer">
            <div
              style="
                color: #fff;
                font-size: 16px;
                width: 200px;
                height: 52px;
                font-weight: 600;
                background: linear-gradient(to right, #8b5ef5, #6d2fff);
                border-radius: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              <div style="padding-top: 5px">
                <!-- <img src="@/assets/imgs/zszh.png" alt="" width="34px" height="32px"> -->
              </div>
              <div>{{ ordinaryWord }}会员</div>
            </div>
          </div>
          <div style="cursor: pointer">
            <!-- <img src="@/assets/imgs/wgj.png" alt=""  width="200px" height="52px"> -->
            <div
              @click="senior(true, 2)"
              style="
                color: #888888;
                font-size: 16px;
                font-weight: 600;
                width: 200px;
                height: 52px;
                background-color: #fff;
                border-radius: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              <div style="padding-top: 10px">
                <!-- <img src="@/assets/imgs/hszs.png" alt="" width="34px" height="32px"> -->
              </div>
              <div>{{ seniorWord }}会员</div>
            </div>
          </div>
        </div>
        <div class="qieStyle" v-show="isShow == true">
          <div style="cursor: pointer">
            <!-- <img src="@/assets/imgs/wpt.png" alt="" @click="senior(false, 1)" width="200px" height="52px"> -->
            <div
              @click="senior(false, 1)"
              style="
                color: #888888;
                font-size: 16px;
                width: 200px;
                height: 52px;
                font-weight: 600;
                border-radius: 30px;
                background-color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              <div style="padding-top: 10px">
                <!-- <img src="@/assets/imgs/hszs.png" alt="" width="34px" height="32px"> -->
              </div>
              <div>{{ ordinaryWord }}会员</div>
            </div>
          </div>
          <div style="cursor: pointer">
            <div
              style="
                color: #fff;
                font-size: 16px;
                width: 200px;
                height: 52px;
                font-weight: 600;
                background: linear-gradient(to right, #8b5ef5, #6d2fff);
                border-radius: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              <div style="padding-top: 5px">
                <!-- <img src="@/assets/imgs/zszh.png" alt="" width="34px" height="32px"> -->
              </div>
              <div>{{ seniorWord }}会员</div>
            </div>
          </div>
        </div>
      </div>

      <div class="person" v-show="isShow == false">
        <div style="display: flex; justify-content: space-around">
          <div class="personStyle" v-for="(list, index) in goodsList" :key="list.index">
            <div>
              <div style="font-size: 20px; color: #171725; font-weight: 600">
                {{ list.name }}
              </div>
              <div style="margin: 5px 0px">
                <span style="font-size: 12px; color: #171725">￥</span>
                <span style="font-size: 32px; color: #171725; font-weight: 600">{{
                  list.price
                }}</span>
                <span
                  v-show="list.memDuration == 1"
                  style="font-size: 12px; color: #9598a7"
                  >/月</span
                >
                <span
                  v-show="list.memDuration == 2"
                  style="font-size: 12px; color: #9598a7"
                  >/季</span
                >
                <span
                  v-show="list.memDuration == 3"
                  style="font-size: 12px; color: #9598a7"
                  >/年</span
                >
              </div>
              <div style="color: #737375; font-size: 12px; text-decoration: line-through">
                ￥{{ list.price * 2 }}
              </div>
              <div>
                <button
                  type="button"
                  class="btn"
                  @click="buyNow(list.id, list.price, list.name, list.memDuration)"
                >
                  立即购买
                </button>
              </div>
              <div
                style="margin: 15px 0px; display: flex; justify-content: space-between"
              >
                <!-- <div style="width: 75px">
                  <div>
                    <img src="@/assets/imgs/ykjvip.png" alt="" width="30px" height="30px" />
                  </div>
                  <div style="color: #b0885f; font-size: 10px">云空间</div>
                </div> -->
                <div style="width: 75px">
                  <div>
                    <img
                      src="@/assets/imgs/huiyuan1.png"
                      alt=""
                      width="40px"
                      height="40px"
                    />
                  </div>
                  <div style="color: #b0885f; font-size: 10px">直播时间</div>
                  <div
                    style="
                      color: #b0885f;
                      font-size: 11px;
                      font-weight: 600;
                      margin-top: 2px;
                    "
                  >
                    不限
                  </div>
                </div>
                <!-- <div style="width: 75px">
                  <div>
                    <img src="@/assets/imgs/xunirenvip.png" alt="" width="30px" height="30px" />
                  </div>
                  <div style="color: #b0885f; font-size: 10px">VIP虚拟人</div>
                </div> -->
                <div style="width: 75px">
                  <div>
                    <img
                      src="@/assets/imgs/huiyuan7.png"
                      alt=""
                      width="40px"
                      height="40px"
                      v-show="voiceVip == false"
                    />
                    <img
                      src="@/assets/imgs/huiyuan2.png"
                      alt=""
                      width="40px"
                      height="40px"
                      v-show="voiceVip == true"
                    />
                  </div>
                  <div style="color: #b0885f; font-size: 10px" v-show="voiceVip == false">
                    数字人合成视频
                  </div>
                  <div style="color: #b0885f; font-size: 10px" v-show="voiceVip == true">
                    直播席位
                  </div>
                  <div
                    style="
                      color: #b0885f;
                      font-size: 11px;
                      font-weight: 600;
                      margin-top: 2px;
                    "
                    v-show="voiceVip == false"
                  >
                    {{ list.duration }}分钟
                  </div>
                  <div
                    style="
                      color: #b0885f;
                      font-size: 11px;
                      font-weight: 600;
                      margin-top: 2px;
                    "
                    v-show="voiceVip == true"
                  >
                    {{ list.duration }}位
                  </div>
                </div>
                <div style="width: 75px">
                  <div>
                    <img
                      src="@/assets/imgs/xinxixuniren.png"
                      alt=""
                      width="40px"
                      height="40px"
                      v-show="voiceVip == false"
                    />
                    <img
                      src="@/assets/imgs/huiyuan3.png"
                      alt=""
                      width="40px"
                      height="40px"
                      v-show="voiceVip == true"
                    />
                  </div>
                  <div style="color: #b0885f; font-size: 10px" v-show="voiceVip == false">
                    数字人形象
                  </div>
                  <div style="color: #b0885f; font-size: 10px" v-show="voiceVip == true">
                    语音音色
                  </div>
                  <div
                    style="
                      color: #b0885f;
                      font-size: 11px;
                      font-weight: 600;
                      margin-top: 2px;
                    "
                    v-show="voiceVip == false"
                  >
                    70+
                  </div>
                  <div
                    style="
                      color: #b0885f;
                      font-size: 11px;
                      font-weight: 600;
                      margin-top: 2px;
                    "
                    v-show="voiceVip == true"
                  >
                    100+
                  </div>
                </div>
                <!-- <div style="width: 75px">
                  <div>
                    <img src="@/assets/imgs/sucai11.png" alt="" width="30px" height="30px" />
                  </div>
                  <div style="color: #b0885f; font-size: 10px">VIP素材资源</div>
                </div> -->
              </div>
              <div
                style="margin: 15px 0px; display: flex; justify-content: space-between"
              >
                <!-- <div style="width: 75px">
                  <div>
                    <img src="@/assets/imgs/hywa.png" alt="" width="30px" height="30px" />
                  </div>
                  <div style="color: #b0885f; font-size: 10px">智能文案</div>
                  <div v-show="list.isAllow == false" style="color: #b0885f; font-size: 10px">次数不限</div>
                  <div v-show="list.isAllow == true" style="color: #b0885f; font-size: 10px">{{ list.num }}次</div>
                </div> -->
                <div style="width: 75px">
                  <div>
                    <img
                      src="@/assets/imgs/huiyuan4.png"
                      alt=""
                      width="40px"
                      height="40px"
                    />
                  </div>
                  <div style="color: #b0885f; font-size: 10px">云空间</div>
                  <div
                    style="
                      color: #b0885f;
                      font-size: 11px;
                      font-weight: 600;
                      margin-top: 2px;
                    "
                  >
                    {{ list.spaceSize }}G
                  </div>
                </div>
                <div style="width: 75px">
                  <div>
                    <img
                      src="@/assets/imgs/huiyuan5.png"
                      alt=""
                      width="40px"
                      height="40px"
                    />
                  </div>
                  <div style="color: #b0885f; font-size: 10px">智能文案</div>
                  <div
                    style="
                      color: #b0885f;
                      font-size: 11px;
                      font-weight: 600;
                      margin-top: 2px;
                    "
                  >
                    次数不限
                  </div>
                  <!-- <div v-show="list.isAllow == true" style="color: #b0885f; font-size: 10px">{{ list.num }}次</div> -->
                </div>
                <div style="width: 75px">
                  <div>
                    <img
                      src="@/assets/imgs/huiyuan6.png"
                      alt=""
                      width="40px"
                      height="40px"
                    />
                  </div>
                  <div style="color: #b0885f; font-size: 10px">VIP素材</div>
                  <div
                    style="
                      color: #b0885f;
                      font-size: 11px;
                      font-weight: 600;
                      margin-top: 2px;
                    "
                  >
                    不限
                  </div>
                </div>
                <!-- <div style="width: 75px">
                  <div>
                    <img src="@/assets/imgs/hyhc.png" alt="" width="30px" height="30px" />
                  </div>
                  <div style="color: #b0885f; font-size: 10px" v-show="voiceVip == false">数字人合成视频</div>
                  <div style="color: #b0885f; font-size: 10px" v-show="voiceVip == true">直播时间</div>
                  <div style="color: #b0885f; font-size: 10px">{{ list.duration }}分钟/月</div>
                </div> -->
                <!-- <div v-show="list.memDuration == 1" style="width: 75px">
                  <div>
                    <img src="@/assets/imgs/hyhc.png" alt="" width="30px" height="30px" />
                  </div>
                  <div style="color: #b0885f; font-size: 10px" v-show="voiceVip == false">数字人合成视频</div>
                  <div style="color: #b0885f; font-size: 10px" v-show="voiceVip == true">直播总时长</div>
                  <div style="color: #b0885f; font-size: 10px">总{{ list.duration * list.memDuration }}分钟</div>
                </div>
                <div v-show="list.memDuration == 2" style="width: 75px">
                  <div>
                    <img src="@/assets/imgs/hyhc.png" alt="" width="30px" height="30px" />
                  </div>
                  <div style="color: #b0885f; font-size: 10px" v-show="voiceVip == false">数字人合成视频</div>
                  <div style="color: #b0885f; font-size: 10px" v-show="voiceVip == true">直播总时长</div>
                  <div style="color: #b0885f; font-size: 10px">总{{ list.duration * 3 }}分钟</div>
                </div>
                <div v-show="list.memDuration == 3" style="width: 75px">
                  <div>
                    <img src="@/assets/imgs/hyhc.png" alt="" width="30px" height="30px" />
                  </div>
                  <div style="color: #b0885f; font-size: 10px" v-show="voiceVip == false">数字人合成视频</div>
                  <div style="color: #b0885f; font-size: 10px" v-show="voiceVip == true">直播总时长</div>
                  <div style="color: #b0885f; font-size: 10px">总{{ list.duration * 12 }}分钟</div>
                </div> -->
              </div>
            </div>
            <div
              style="position: absolute; top: -10px; left: 0px"
              v-show="list.memDuration == 3"
            >
              <img src="@/assets/imgs/sckt.png" alt="" />
              <div
                style="
                  color: #fff304;
                  font-size: 15px;
                  font-weight: 600;
                  position: absolute;
                  top: 3px;
                  left: 60px;
                "
              >
                送定制场景装潢一套
              </div>
            </div>
          </div>
        </div>
        <div style="position: relative; margin-left: 20px">
          <div
            class="personStyle1"
            style="background: linear-gradient(to right, #fff1dc, #ffe2c1)"
          >
            <div>
              <div
                v-show="goodsType == 2"
                style="color: #956535; font-size: 16px; font-weight: 600"
              >
                数字语音加油包
              </div>
              <div
                v-show="goodsType == 1"
                style="color: #956535; font-size: 16px; font-weight: 600"
              >
                数字人加油包
              </div>
              <div>
                <span style="font-size: 12px; color: #956535">￥</span>
                <span style="font-size: 24px; color: #956535">10</span>
                <span style="font-size: 12px; color: #956535">起</span>
              </div>
            </div>
            <div style="cursor: pointer" @click="refuelingBag(1)">
              <img src="@/assets/imgs/gengduo.png" alt="" />
            </div>
          </div>
          <div
            class="personStyle1"
            style="
              margin-top: 15px;
              background: linear-gradient(to right, #fcefe6, #ffece5);
            "
          >
            <div>
              <div style="color: #956535; font-size: 16px; font-weight: 600">
                智能文案加油包
              </div>
              <div>
                <span style="font-size: 12px; color: #956535">￥</span>
                <span style="font-size: 24px; color: #956535">10</span>
                <span style="font-size: 12px; color: #956535">起</span>
              </div>
            </div>
            <div style="cursor: pointer" @click="refuelingBag(2)">
              <img src="@/assets/imgs/gengduo.png" alt="" />
            </div>
          </div>
          <div
            class="personStyle1"
            style="
              margin-top: 15px;
              background: linear-gradient(to right, #6f66f5, #b6b6ff);
              cursor: pointer;
              position: relative;
            "
            @click="customized"
          >
            <div style="width: 100%; height: 100%; position: absolute">
              <img
                src="@/assets/imgs/dingzhibeijing.png"
                alt=""
                height="100%"
                width="100%"
              />
            </div>
            <div style="position: absolute; left: 20px">
              <div
                style="color: #fff; font-size: 16px; font-weight: 600; cursor: pointer"
              >
                定制数字人
              </div>
            </div>
            <div style="cursor: pointer; margin-left: 20px">
              <!-- <img src="@/assets/imgs/gengduo.png" alt=""> -->
            </div>
          </div>
        </div>
      </div>
      <div class="person" v-show="isShow == true">
        <div style="display: flex; justify-content: space-around">
          <div class="personStyle" v-for="(list, index) in goodsList" :key="list.index">
            <div>
              <div style="font-size: 20px; color: #171725; font-weight: 600">
                {{ list.name }}
              </div>
              <div style="margin: 5px 0px">
                <span style="font-size: 12px; color: #171725">￥</span>
                <span style="font-size: 32px; color: #171725; font-weight: 600">{{
                  list.price
                }}</span>
                <span
                  v-show="list.memDuration == 1"
                  style="font-size: 12px; color: #9598a7"
                  >/月</span
                >
                <span
                  v-show="list.memDuration == 2"
                  style="font-size: 12px; color: #9598a7"
                  >/季</span
                >
                <span
                  v-show="list.memDuration == 3"
                  style="font-size: 12px; color: #9598a7"
                  >/年</span
                >
              </div>
              <div style="color: #737375; font-size: 12px; text-decoration: line-through">
                ￥{{ list.price * 2 }}
              </div>
              <div>
                <button
                  type="button"
                  class="btn"
                  @click="buyNow(list.id, list.price, list.name, list.memDuration)"
                >
                  立即购买
                </button>
              </div>
              <div
                style="margin: 15px 0px; display: flex; justify-content: space-between"
              >
                <!-- <div style="width: 75px">
                  <div>
                    <img src="@/assets/imgs/ykjvip.png" alt="" width="30px" height="30px" />
                  </div>
                  <div style="color: #b0885f; font-size: 10px">云空间</div>
                </div> -->
                <div style="width: 75px">
                  <div>
                    <img
                      src="@/assets/imgs/huiyuan1.png"
                      alt=""
                      width="40px"
                      height="40px"
                    />
                  </div>
                  <div style="color: #b0885f; font-size: 10px">直播时间</div>
                  <div
                    style="
                      color: #b0885f;
                      font-size: 11px;
                      font-weight: 600;
                      margin-top: 2px;
                    "
                  >
                    不限
                  </div>
                </div>
                <!-- <div style="width: 75px">
                  <div>
                    <img src="@/assets/imgs/xunirenvip.png" alt="" width="30px" height="30px" />
                  </div>
                  <div style="color: #b0885f; font-size: 10px">VIP虚拟人</div>
                </div> -->
                <div style="width: 75px">
                  <div>
                    <img
                      src="@/assets/imgs/huiyuan7.png"
                      alt=""
                      width="40px"
                      height="40px"
                      v-show="voiceVip == false"
                    />
                    <img
                      src="@/assets/imgs/huiyuan2.png"
                      alt=""
                      width="40px"
                      height="40px"
                      v-show="voiceVip == true"
                    />
                  </div>
                  <div style="color: #b0885f; font-size: 10px" v-show="voiceVip == false">
                    数字人合成视频
                  </div>
                  <div style="color: #b0885f; font-size: 10px" v-show="voiceVip == true">
                    直播席位
                  </div>
                  <div
                    style="
                      color: #b0885f;
                      font-size: 11px;
                      font-weight: 600;
                      margin-top: 2px;
                    "
                    v-show="voiceVip == false"
                  >
                    {{ list.duration }}分钟
                  </div>
                  <div
                    style="
                      color: #b0885f;
                      font-size: 11px;
                      font-weight: 600;
                      margin-top: 2px;
                    "
                    v-show="voiceVip == true"
                  >
                    {{ list.duration }}位
                  </div>
                </div>
                <div style="width: 75px">
                  <div>
                    <img
                      src="@/assets/imgs/xinxixuniren.png"
                      alt=""
                      width="40px"
                      height="40px"
                      v-show="voiceVip == false"
                    />
                    <img
                      src="@/assets/imgs/huiyuan3.png"
                      alt=""
                      width="40px"
                      height="40px"
                      v-show="voiceVip == true"
                    />
                  </div>
                  <div style="color: #b0885f; font-size: 10px" v-show="voiceVip == false">
                    数字人形象
                  </div>
                  <div style="color: #b0885f; font-size: 10px" v-show="voiceVip == true">
                    语音音色
                  </div>
                  <div
                    style="
                      color: #b0885f;
                      font-size: 11px;
                      font-weight: 600;
                      margin-top: 2px;
                    "
                    v-show="voiceVip == false"
                  >
                    70+
                  </div>
                  <div
                    style="
                      color: #b0885f;
                      font-size: 11px;
                      font-weight: 600;
                      margin-top: 2px;
                    "
                    v-show="voiceVip == true"
                  >
                    100+
                  </div>
                </div>
                <!-- <div style="width: 75px">
                  <div>
                    <img src="@/assets/imgs/sucai11.png" alt="" width="30px" height="30px" />
                  </div>
                  <div style="color: #b0885f; font-size: 10px">VIP素材资源</div>
                </div> -->
              </div>
              <div
                style="margin: 15px 0px; display: flex; justify-content: space-between"
              >
                <!-- <div style="width: 75px">
                  <div>
                    <img src="@/assets/imgs/hywa.png" alt="" width="30px" height="30px" />
                  </div>
                  <div style="color: #b0885f; font-size: 10px">智能文案</div>
                  <div v-show="list.isAllow == false" style="color: #b0885f; font-size: 10px">次数不限</div>
                  <div v-show="list.isAllow == true" style="color: #b0885f; font-size: 10px">{{ list.num }}次</div>
                </div> -->
                <div style="width: 75px">
                  <div>
                    <img
                      src="@/assets/imgs/huiyuan4.png"
                      alt=""
                      width="40px"
                      height="40px"
                    />
                  </div>
                  <div style="color: #b0885f; font-size: 10px">云空间</div>
                  <div
                    style="
                      color: #b0885f;
                      font-size: 11px;
                      font-weight: 600;
                      margin-top: 2px;
                    "
                  >
                    {{ list.spaceSize }}G
                  </div>
                </div>
                <div style="width: 75px">
                  <div>
                    <img
                      src="@/assets/imgs/huiyuan5.png"
                      alt=""
                      width="40px"
                      height="40px"
                    />
                  </div>
                  <div style="color: #b0885f; font-size: 10px">智能文案</div>
                  <div
                    style="
                      color: #b0885f;
                      font-size: 11px;
                      font-weight: 600;
                      margin-top: 2px;
                    "
                  >
                    次数不限
                  </div>
                  <!-- <div v-show="list.isAllow == true" style="color: #b0885f; font-size: 10px">{{ list.num }}次</div> -->
                </div>
                <div style="width: 75px">
                  <div>
                    <img
                      src="@/assets/imgs/huiyuan6.png"
                      alt=""
                      width="40px"
                      height="40px"
                    />
                  </div>
                  <div style="color: #b0885f; font-size: 10px">VIP素材</div>
                  <div
                    style="
                      color: #b0885f;
                      font-size: 11px;
                      font-weight: 600;
                      margin-top: 2px;
                    "
                  >
                    不限
                  </div>
                </div>
                <!-- <div style="width: 75px">
                  <div>
                    <img src="@/assets/imgs/hyhc.png" alt="" width="30px" height="30px" />
                  </div>
                  <div style="color: #b0885f; font-size: 10px" v-show="voiceVip == false">数字人合成视频</div>
                  <div style="color: #b0885f; font-size: 10px" v-show="voiceVip == true">直播时间</div>
                  <div style="color: #b0885f; font-size: 10px">{{ list.duration }}分钟/月</div>
                </div> -->
                <!-- <div v-show="list.memDuration == 1" style="width: 75px">
                  <div>
                    <img src="@/assets/imgs/hyhc.png" alt="" width="30px" height="30px" />
                  </div>
                  <div style="color: #b0885f; font-size: 10px" v-show="voiceVip == false">数字人合成视频</div>
                  <div style="color: #b0885f; font-size: 10px" v-show="voiceVip == true">直播总时长</div>
                  <div style="color: #b0885f; font-size: 10px">总{{ list.duration * list.memDuration }}分钟</div>
                </div>
                <div v-show="list.memDuration == 2" style="width: 75px">
                  <div>
                    <img src="@/assets/imgs/hyhc.png" alt="" width="30px" height="30px" />
                  </div>
                  <div style="color: #b0885f; font-size: 10px" v-show="voiceVip == false">数字人合成视频</div>
                  <div style="color: #b0885f; font-size: 10px" v-show="voiceVip == true">直播总时长</div>
                  <div style="color: #b0885f; font-size: 10px">总{{ list.duration * 3 }}分钟</div>
                </div>
                <div v-show="list.memDuration == 3" style="width: 75px">
                  <div>
                    <img src="@/assets/imgs/hyhc.png" alt="" width="30px" height="30px" />
                  </div>
                  <div style="color: #b0885f; font-size: 10px" v-show="voiceVip == false">数字人合成视频</div>
                  <div style="color: #b0885f; font-size: 10px" v-show="voiceVip == true">直播总时长</div>
                  <div style="color: #b0885f; font-size: 10px">总{{ list.duration * 12 }}分钟</div>
                </div> -->
              </div>
            </div>
            <div
              style="position: absolute; top: -10px; left: 0px"
              v-show="list.memDuration == 3"
            >
              <img src="@/assets/imgs/sckt.png" alt="" />
              <div
                style="
                  color: #fff304;
                  font-size: 15px;
                  font-weight: 600;
                  position: absolute;
                  top: 3px;
                  left: 60px;
                "
              >
                送定制场景装潢三套
              </div>
            </div>
            <div
              style="position: absolute; top: -10px; left: 0px"
              v-show="list.memDuration == 2"
            >
              <img src="@/assets/imgs/sckt.png" alt="" />
              <div
                style="
                  color: #fff304;
                  font-size: 15px;
                  font-weight: 600;
                  position: absolute;
                  top: 3px;
                  left: 60px;
                "
              >
                送定制场景装潢二套
              </div>
            </div>
            <div
              style="position: absolute; top: -10px; left: 0px"
              v-show="list.memDuration == 1"
            >
              <img src="@/assets/imgs/sckt.png" alt="" />
              <div
                style="
                  color: #fff304;
                  font-size: 15px;
                  font-weight: 600;
                  position: absolute;
                  top: 3px;
                  left: 60px;
                "
              >
                送定制场景装潢一套
              </div>
            </div>
          </div>
        </div>
        <div style="position: relative; margin-left: 20px">
          <div
            class="personStyle1"
            style="background: linear-gradient(to right, #fff1dc, #ffe2c1)"
          >
            <div>
              <!-- v-show="goodsType == 1" -->
              <div
                v-show="goodsType == 2"
                style="color: #956535; font-size: 16px; font-weight: 600"
              >
                数字语音加油包
              </div>
              <div
                v-show="goodsType == 1"
                style="color: #956535; font-size: 16px; font-weight: 600"
              >
                数字人加油包
              </div>
              <div>
                <span style="font-size: 12px; color: #956535">￥</span>
                <span style="font-size: 24px; color: #956535">10</span>
                <span style="font-size: 12px; color: #956535">起</span>
              </div>
            </div>
            <div style="cursor: pointer" @click="refuelingBag(1)">
              <img src="@/assets/imgs/gengduo.png" alt="" />
            </div>
          </div>
          <div
            class="personStyle1"
            style="
              margin-top: 15px;
              background: linear-gradient(to right, #fcefe6, #ffece5);
            "
          >
            <div>
              <div style="color: #956535; font-size: 16px; font-weight: 600">
                智能文案加油包
              </div>
              <div>
                <span style="font-size: 12px; color: #956535">￥</span>
                <span style="font-size: 24px; color: #956535">10</span>
                <span style="font-size: 12px; color: #956535">起</span>
              </div>
            </div>
            <div style="cursor: pointer" @click="refuelingBag(2)">
              <img src="@/assets/imgs/gengduo.png" alt="" />
            </div>
          </div>
          <div
            class="personStyle1"
            style="
              margin-top: 15px;
              background: linear-gradient(to right, #6f66f5, #b6b6ff);
              cursor: pointer;
              position: relative;
            "
            @click="customized"
          >
            <div style="width: 100%; height: 100%; position: absolute">
              <img
                src="@/assets/imgs/dingzhibeijing.png"
                alt=""
                height="100%"
                width="100%"
              />
            </div>
            <div style="position: absolute; left: 20px">
              <div
                style="color: #fff; font-size: 16px; font-weight: 600; cursor: pointer"
              >
                定制数字人
              </div>
            </div>
            <!-- <div style="cursor: pointer; margin-left: 20px" @click="customized"> -->
            <!-- <img src="@/assets/imgs/gengduo.png" alt=""> -->
            <!-- </div> -->
          </div>
        </div>
      </div>

      <el-dialog
        title="支付确认"
        :visible.sync="dialogPay"
        width="480px"
        class="center-title"
        center
        :before-close="handleClose"
      >
        <div class="pay_or_not">
          <div class="payNo" @click="payNo">未支付</div>
          <div class="payOk" @click="payOk">已支付</div>
        </div>
      </el-dialog>

      <el-dialog
        title="会员支付"
        :visible.sync="dialogVisible"
        width="480px"
        class="center-title"
        center
        :before-close="handleClose"
      >
        <div class="buyStyle">
          <div class="helloStyle">
            <!-- -->
            <span v-show="goodsType == 1" style="color: #ffbd5a"
              >数字人直播<span style="margin: 0 3px">-</span></span
            >
            <span v-show="goodsType == 2" style="color: #ffbd5a"
              >数字语音直播<span>-</span></span
            >
            <span> {{ name }}</span>
          </div>
          <div style="margin-top: 15px">
            <span style="font-size: 12px; color: #333333">￥</span>
            <span style="font-size: 36px; color: #333333; font-weight: 600">{{
              price
            }}</span>
            <span v-show="memDuration == 1" style="font-size: 12px; color: #9598a7"
              >/月</span
            >
            <span v-show="memDuration == 2" style="font-size: 12px; color: #9598a7"
              >/季</span
            >
            <span v-show="memDuration == 3" style="font-size: 12px; color: #9598a7"
              >/年</span
            >
          </div>
          <div v-show="payCode === 2" class="codeM" style="margin-top: 15px">
            <div
              id="qrcode"
              style="
                padding: 10px;
                box-shadow: 1px 1px 5px 1px rgb(196, 196, 196);
                background-color: #fff;
                border-radius: 6px;
                width: 230px;
                height: 230px;
                overflow: hidden;
                margin-bottom: 15px;
              "
            ></div>
            <div
              style="
                color: #333333;
                margin-top: 10px;
                margin-left: -60px;
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <img style="margin-right: 10px" src="@/assets/imgs/wxsmzf.png" alt="" />
              微信扫码支付
            </div>
          </div>
          <div class="Payment-Box" v-if="payCode === 1">
            <div class="weixin" @click="payWx">
              <div class="icon">
                <img src="@/assets/imgs/pay-wx.png" alt="" />
              </div>
              <div class="text">微信支付</div>
            </div>
            <div class="zhifubao" @click="payZfb">
              <div class="icon">
                <img src="@/assets/imgs/pay-zfb.png" alt="" />
              </div>
              <div class="text">支付宝支付</div>
            </div>
          </div>
          <div class="PaymentOk" v-if="payCode === 3">
            <div class="payOk">
              <img src="@/assets/imgs/wxOk.png" alt="" />
            </div>
          </div>
        </div>
      </el-dialog>

      <el-dialog
        :title="title"
        :visible.sync="dialogVisible1"
        width="1061px"
        :before-close="handleClose"
      >
        <div class="userStyle-refueling">
          <div class="qieStyle1" v-show="isShow2 == false">
            <div
              style="position: absolute; left: 590px; cursor: pointer"
              @click="senior1(true, 2)"
            >
              <!-- <img src="@/assets/imgs/znyy1.png" alt="" width="240px" height="52px"> -->
              <div
                style="
                  width: 240px;
                  height: 52px;
                  color: #fff;
                  font-weight: 600;
                  background-color: #cdbdf7;
                  border-radius: 30px;
                  font-size: 16px;
                  text-align: center;
                  line-height: 52px;
                "
              >
                智能文案加油包
              </div>
            </div>
            <div style="position: absolute; left: 400px; cursor: pointer">
              <!-- <img src="@/assets/imgs/yuyinjybw.png" alt=""width="240px" height="52px"> -->
              <div
                @click="senior1(3, 3)"
                style="
                  width: 240px;
                  color: #fff;
                  font-weight: 600;
                  height: 52px;
                  background-color: #cdbdf7;
                  border-radius: 30px;
                  font-size: 16px;
                  text-align: center;
                  line-height: 52px;
                "
              >
                数字语音加油包
              </div>
            </div>
            <div style="position: absolute; left: 210px; cursor: pointer; top: -2px">
              <img src="@/assets/imgs/znwa2.png" alt="" width="240px" height="56px" />
            </div>
          </div>
          <div class="qieStyle1" v-show="isShow2 == true">
            <div style="position: absolute; cursor: pointer; left: 210px">
              <!-- <img src="@/assets/imgs/znwa1.png" alt="" @click="senior1(false, 1)" width="240px" height="52px"> -->
              <div
                @click="senior1(false, 1)"
                style="
                  color: #fff;
                  font-weight: 600;
                  width: 240px;
                  height: 52px;
                  background-color: #cdbdf7;
                  border-radius: 30px;
                  font-size: 16px;
                  text-align: center;
                  line-height: 52px;
                "
              >
                数字人加油包
              </div>
            </div>

            <div style="position: absolute; left: 400px; cursor: pointer">
              <!-- <img src="@/assets/imgs/yuyinjybw.png" alt="" @click="senior1(3, 3)" width="240px" height="52px"> -->
              <div
                @click="senior1(3, 3)"
                style="
                  color: #fff;
                  font-weight: 600;
                  width: 240px;
                  height: 52px;
                  background-color: #cdbdf7;
                  border-radius: 30px;
                  font-size: 16px;
                  text-align: center;
                  line-height: 52px;
                "
              >
                数字语音加油包
              </div>
            </div>
            <div style="position: absolute; left: 590px; top: -2px">
              <img src="@/assets/imgs/znyy2.png" alt="" width="240px" height="56px" />
            </div>
          </div>
          <div class="qieStyle1" v-show="isShow2 == 3">
            <div style="position: absolute; cursor: pointer; left: 210px">
              <!-- <img src="@/assets/imgs/znwa1.png" alt="" @click="senior1(false, 1)" width="240px" height="52px"> -->
              <div
                @click="senior1(false, 1)"
                style="
                  color: #fff;
                  font-weight: 600;
                  width: 240px;
                  height: 52px;
                  background-color: #cdbdf7;
                  border-radius: 30px;
                  font-size: 16px;
                  text-align: center;
                  line-height: 52px;
                "
              >
                数字人加油包
              </div>
            </div>
            <div
              style="
                position: absolute;
                left: 400px;
                cursor: pointer;
                z-index: 999999;
                top: -2px;
              "
            >
              <img src="@/assets/imgs/yuyinjybx.png" width="240px" height="56px" />
            </div>
            <div style="position: absolute; left: 590px; cursor: pointer">
              <!-- <img src="@/assets/imgs/znyy1.png" alt="" width="240px" height="52px" @click="senior1(true, 2)"> -->
              <div
                @click="senior1(true, 2)"
                style="
                  color: #fff;
                  font-weight: 600;
                  width: 240px;
                  height: 52px;
                  background-color: #cdbdf7;
                  border-radius: 30px;
                  font-size: 16px;
                  text-align: center;
                  line-height: 52px;
                "
              >
                智能文案加油包
              </div>
            </div>
          </div>
          <div class="person1" v-show="isShow2 == false">
            <!-- <div v-show="isShowEr == ''"
                style="width: 100%;height: 100px;background-color: rgba(0, 0, 0, 0);position: absolute;"></div> -->
            <div style="display: flex; justify-content: space-evenly">
              <div
                :class="list.id == listId ? 'personStyle4' : 'personStyle3'"
                v-for="(list, index) in goodsList1"
                :key="list.index"
                @click="buyRefueling(list, 1)"
              >
                <div>
                  <div style="font-size: 16px; font-weight: 600">
                    {{ list.equity }}分钟
                  </div>
                  <div style="margin: 5px 0px">
                    <span style="font-size: 12px">折合{{ list.average }}元/分钟</span>
                  </div>
                </div>
                <div>
                  <div>
                    <span style="font-size: 12px">￥</span>
                    <span style="font-size: 24px">{{ list.price }}</span>
                  </div>
                  <div>
                    <div style="font-size: 12px; text-decoration: line-through">
                      ￥{{ list.price * 2 }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="personStyle5">
              <div style="font-size: 16px; color: #171725; font-weight: 600">
                规则说明
              </div>
              <div style="font-size: 12px; color: #171725; margin-top: 10px">
                1.单次购买有效期为一个月，可叠加购买（若同时购买两个10分钟的加油包，可用额度为20分钟，有效期为一个月）
              </div>
              <div style="font-size: 12px; color: #171725; margin-top: 10px">
                2.同时拥有多个数字人加油包或会员时长时，优先使用有效期短的时长
              </div>

              <div class="zhifuWay">
                <div class="title">支付方式:</div>
                <div class="wx" v-if="zhifuStatus === 1" @click="zhifuStatusHandel(2)">
                  <img src="@/assets/imgs/weixin_zf.png" alt="" />微信支付
                </div>
                <div class="wxXz" v-else>
                  <img src="@/assets/imgs/weixin_zf.png" alt="" />微信支付
                </div>
                <div class="zfb" v-if="zhifuStatus === 2" @click="zhifuStatusHandel(1)">
                  <img src="@/assets/imgs/zhifubao_zf.png" alt="" />支付宝支付
                </div>
                <div class="zfbXz" v-else>
                  <img src="@/assets/imgs/zhifubao_zf.png" alt="" />支付宝支付
                </div>
              </div>

              <div v-if="zhifuStatus === 2" style="display: flex; align-items: center; margin-top: 10px">
                <div
                  style="
                    width: 160px;
                    height: 160px;
                    background-color: #fff;
                    border-radius: 11px;
                  "
                >
                  <div v-show="paymentShow == 1">
                    <img
                      src="@/assets/imgs/zferweima.png"
                      alt=""
                      width="100%"
                      height="100%"
                    />
                  </div>
                  <div
                    style="
                      margin-top: 10px;
                      margin-left: 10px;
                      border-radius: 11px;
                      overflow: hidden;
                    "
                    id="qrcode"
                    v-show="paymentShow == 2"
                  ></div>
                  <div v-show="paymentShow == 3">
                    <img src="@/assets/imgs/cg.png" alt="" />
                    <div style="font-size: 14px; color: #171725; margin-left: 60px">
                      支付成功
                    </div>
                  </div>
                </div>
                <div>
                  <div
                    style="
                      margin-left: 20px;
                      display: flex;
                      align-items: baseline;
                      margin-bottom: 15px;
                    "
                  >
                    <div>
                      <img
                        src="@/assets/imgs/wxsmzf.png"
                        alt=""
                        style="margin-right: 8px"
                      />
                    </div>
                    <div style="font-size: 16px; color: #171725">
                      微信扫码支付，实付：
                    </div>
                    <div style="font-size: 41px; color: #e33f2d">
                      <span style="font-size: 24px">￥</span> {{ money }}
                    </div>
                  </div>
                  <div
                    v-show="buySueShow == 1"
                    style="
                      margin-left: 20px;
                      font-size: 22px;
                      background-color: #8b5ef5;
                      width: 200px;
                      height: 60px;
                      border-radius: 8px;
                      color: #fff;
                      line-height: 60px;
                      text-align: center;
                      cursor: pointer;
                    "
                    @click="buySue"
                  >
                    立即购买
                  </div>
                  <div
                    v-show="buySueShow == 2"
                    style="
                      margin-left: 20px;
                      font-size: 22px;
                      background-color: #8b5ef5;
                      width: 200px;
                      height: 60px;
                      border-radius: 8px;
                      color: #fff;
                      line-height: 60px;
                      text-align: center;
                      cursor: not-allowed;
                    "
                  >
                    请扫码支付
                  </div>
                  <div style="margin-left: 20px; margin-top: 15px">
                    <span style="color: #a6a6a6">支付即视为同意</span>
                    <span style="color: #8b5ef5; cursor: pointer" @click="pdfFile"
                      >《用户协议条例》</span
                    >
                  </div>
                </div>
              </div>
              <div v-if="zhifuStatus === 1" style="display: flex; align-items: center; margin-top: 10px">
                <div
                  style="
                    width: 160px;
                    height: 160px;
                    background-color: #fff;
                    border-radius: 11px;
                  "
                >
                  <div>
                    <img
                      src="@/assets/imgs/zferweima.png"
                      alt=""
                      width="100%"
                      height="100%"
                    />
                  </div>
                </div>
                <div>
                  <div
                    style="
                      margin-left: 20px;
                      display: flex;
                      align-items: baseline;
                      margin-bottom: 15px;
                    "
                  >
                    <div>
                      <img
                        src="@/assets/imgs/zfbZf.png"
                        alt=""
                        style="margin-right: 8px"
                      />
                    </div>
                    <div style="font-size: 16px; color: #171725">
                     支付宝支付，实付：
                    </div>
                    <div style="font-size: 41px; color: #e33f2d">
                      <span style="font-size: 24px">￥</span> {{ money }}
                    </div>
                  </div>
                  <div
                    style="
                      margin-left: 20px;
                      font-size: 22px;
                      background-color: #8b5ef5;
                      width: 200px;
                      height: 60px;
                      border-radius: 8px;
                      color: #fff;
                      line-height: 60px;
                      text-align: center;
                      cursor: pointer;
                    "
                    @click="buySueZfb"
                  >
                    立即购买
                  </div>
                  <div style="margin-left: 20px; margin-top: 15px">
                    <span style="color: #a6a6a6">支付即视为同意</span>
                    <span style="color: #8b5ef5; cursor: pointer" @click="pdfFile"
                      >《用户协议条例》</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="person1" v-show="isShow2 == true">
            <!-- <div v-show="isShowEr1 == ''"
                style="width: 100%;height: 100px;background-color: rgba(0, 0, 0, 0);position: absolute;"></div> -->
            <div style="display: flex; justify-content: space-evenly">
              <div
                :class="list.id == listId ? 'personStyle4' : 'personStyle3'"
                v-for="(list, index) in goodsList1"
                :key="list.index"
                @click="buyRefueling(list, 2)"
              >
                <div>
                  <div style="font-size: 16px; font-weight: 600">{{ list.equity }}次</div>
                  <div style="margin: 5px 0px">
                    <span style="font-size: 12px">折合{{ list.average }}元/次</span>
                  </div>
                </div>
                <div>
                  <div>
                    <span style="font-size: 12px">￥</span>
                    <span style="font-size: 24px">{{ list.price }}</span>
                  </div>
                  <div>
                    <div style="font-size: 12px; text-decoration: line-through">
                      ￥{{ list.price * 2 }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="personStyle5">
              <div style="font-size: 16px; color: #171725; font-weight: 600">
                规则说明
              </div>
              <div style="font-size: 12px; color: #171725; margin-top: 10px">
                1.单次购买有效期为一个月，可叠加购买（若同时购买两个10次的加油包，可用额度为20分次，有效期为一个月）
              </div>

              <div class="zhifuWay">
                <div class="title">支付方式:</div>
                <div class="wx" v-if="zhifuStatus === 1" @click="zhifuStatusHandel(2)">
                  <img src="@/assets/imgs/weixin_zf.png" alt="" />微信支付
                </div>
                <div class="wxXz" v-else>
                  <img src="@/assets/imgs/weixin_zf.png" alt="" />微信支付
                </div>
                <div class="zfb" v-if="zhifuStatus === 2" @click="zhifuStatusHandel(1)">
                  <img src="@/assets/imgs/zhifubao_zf.png" alt="" />支付宝支付
                </div>
                <div class="zfbXz" v-else>
                  <img src="@/assets/imgs/zhifubao_zf.png" alt="" />支付宝支付
                </div>
              </div>

              <div v-if="zhifuStatus === 2" style="display: flex; align-items: center; margin-top: 10px">
                <div
                  style="
                    width: 160px;
                    height: 160px;
                    background-color: #fff;
                    border-radius: 11px;
                  "
                >
                  <div v-show="paymentShow1 == 1">
                    <img
                      src="@/assets/imgs/zferweima.png"
                      alt=""
                      width="100%"
                      height="100%"
                    />
                  </div>
                  <div
                    class="qrcode1Style"
                    style=""
                    id="qrcode1"
                    v-show="paymentShow1 == 2"
                  ></div>
                  <div v-show="paymentShow1 == 3">
                    <img src="@/assets/imgs/cg.png" alt="" />
                    <div style="font-size: 14px; color: #171725; margin-left: 60px">
                      支付成功
                    </div>
                  </div>
                </div>

                <div>
                  <div
                    style="
                      margin-left: 20px;
                      display: flex;
                      align-items: baseline;
                      margin-bottom: 15px;
                    "
                  >
                    <div>
                      <img
                        src="@/assets/imgs/wxsmzf.png"
                        alt=""
                        style="margin-right: 8px"
                      />
                    </div>
                    <div style="font-size: 16px; color: #171725">
                      微信扫码支付，实付：
                    </div>
                    <div style="font-size: 41px; color: #e33f2d">
                      <span style="font-size: 24px">￥</span>{{ money }}
                    </div>
                  </div>
                  <div
                    v-show="buySueShow == 1"
                    style="
                      margin-left: 20px;
                      font-size: 22px;
                      background-color: #8b5ef5;
                      width: 200px;
                      height: 60px;
                      border-radius: 8px;
                      color: #fff;
                      line-height: 60px;
                      text-align: center;
                      cursor: pointer;
                    "
                    @click="buySue"
                  >
                    立即购买
                  </div>
                  <div
                    v-show="buySueShow == 2"
                    style="
                      margin-left: 20px;
                      font-size: 22px;
                      background-color: #8b5ef5;
                      width: 200px;
                      height: 60px;
                      border-radius: 8px;
                      color: #fff;
                      line-height: 60px;
                      text-align: center;
                      cursor: not-allowed;
                    "
                  >
                    请扫码支付
                  </div>
                  <div style="margin-left: 20px; margin-top: 15px">
                    <span style="color: #a6a6a6">支付即视为同意</span>
                    <span style="color: #8b5ef5; cursor: pointer" @click="pdfFile"
                      >《用户协议条例》</span
                    >
                  </div>
                </div>
              </div>
              <div v-if="zhifuStatus === 1" style="display: flex; align-items: center; margin-top: 10px">
                <div
                  style="
                    width: 160px;
                    height: 160px;
                    background-color: #fff;
                    border-radius: 11px;
                  "
                >
                  <div>
                    <img
                      src="@/assets/imgs/zferweima.png"
                      alt=""
                      width="100%"
                      height="100%"
                    />
                  </div>
                </div>
                <div>
                  <div
                    style="
                      margin-left: 20px;
                      display: flex;
                      align-items: baseline;
                      margin-bottom: 15px;
                    "
                  >
                    <div>
                      <img
                        src="@/assets/imgs/zfbZf.png"
                        alt=""
                        style="margin-right: 8px"
                      />
                    </div>
                    <div style="font-size: 16px; color: #171725">
                     支付宝支付，实付：
                    </div>
                    <div style="font-size: 41px; color: #e33f2d">
                      <span style="font-size: 24px">￥</span> {{ money }}
                    </div>
                  </div>
                  <div
                    style="
                      margin-left: 20px;
                      font-size: 22px;
                      background-color: #8b5ef5;
                      width: 200px;
                      height: 60px;
                      border-radius: 8px;
                      color: #fff;
                      line-height: 60px;
                      text-align: center;
                      cursor: pointer;
                    "
                    @click="buySueZfb"
                  >
                    立即购买
                  </div>
                  <div style="margin-left: 20px; margin-top: 15px">
                    <span style="color: #a6a6a6">支付即视为同意</span>
                    <span style="color: #8b5ef5; cursor: pointer" @click="pdfFile"
                      >《用户协议条例》</span
                    >
                  </div>
                </div>
              </div>
            </div>
            
          </div>
          <div class="person1" v-show="isShow2 == 3">
            <div style="display: flex; justify-content: space-evenly">
              <div
                :class="list.id == listId ? 'personStyle4' : 'personStyle3'"
                v-for="(list, index) in goodsList1"
                :key="list.index"
                @click="buyRefueling(list, 3)"
              >
                <div>
                  <div style="font-size: 16px; font-weight: 600">{{ list.equity }}位</div>
                  <div style="margin: 5px 0px">
                    <span style="font-size: 12px">折合{{ list.average }}元/位</span>
                  </div>
                </div>
                <div>
                  <div>
                    <span style="font-size: 12px">￥</span>
                    <span style="font-size: 24px">{{ list.price }}</span>
                  </div>
                  <div>
                    <div style="font-size: 12px; text-decoration: line-through">
                      ￥{{ list.price * 2 }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="personStyle5">
              <div style="font-size: 16px; color: #171725; font-weight: 600">
                规则说明
              </div>
              <div style="font-size: 12px; color: #171725; margin-top: 10px">
                1.单次购买有效期为一个月，可叠加购买，若购同时购买两个1位的加油包，可用额度为2位
              </div>

              <div class="zhifuWay">
                <div class="title">支付方式:</div>
                <div class="wx" v-if="zhifuStatus === 1" @click="zhifuStatusHandel(2)">
                  <img src="@/assets/imgs/weixin_zf.png" alt="" />微信支付
                </div>
                <div class="wxXz" v-else>
                  <img src="@/assets/imgs/weixin_zf.png" alt="" />微信支付
                </div>
                <div class="zfb" v-if="zhifuStatus === 2" @click="zhifuStatusHandel(1)">
                  <img src="@/assets/imgs/zhifubao_zf.png" alt="" />支付宝支付
                </div>
                <div class="zfbXz" v-else>
                  <img src="@/assets/imgs/zhifubao_zf.png" alt="" />支付宝支付
                </div>
              </div>

              <div style="display: flex; align-items: center; margin-top: 10px">
                <div
                  style="
                    width: 160px;
                    height: 160px;
                    background-color: #fff;
                    border-radius: 11px;
                  "
                >
                  <div v-show="paymentShow2 == 1">
                    <img
                      src="@/assets/imgs/zferweima.png"
                      alt=""
                      width="100%"
                      height="100%"
                    />
                  </div>
                  <div
                    class="qrcode1Style"
                    style=""
                    id="qrcode2"
                    v-show="paymentShow2 == 2"
                  ></div>
                  <div v-show="paymentShow2 == 3">
                    <img src="@/assets/imgs/cg.png" alt="" />
                    <div style="font-size: 14px; color: #171725; margin-left: 60px">
                      支付成功
                    </div>
                  </div>
                </div>

                <div v-if="zhifuStatus === 2">
                  <div style="margin-left: 20px;display: flex;align-items: baseline;margin-bottom: 15px;">
                    <div>
                      <img
                        src="@/assets/imgs/wxsmzf.png"
                        alt=""
                        style="margin-right: 8px"
                      />
                    </div>
                    <div style="font-size: 16px; color: #171725">
                      微信扫码支付，实付：
                    </div>
                    <div style="font-size: 41px; color: #e33f2d">
                      <span style="font-size: 24px">￥</span>{{ money }}
                    </div>
                  </div>
                  <div
                    v-show="buySueShow == 1"
                    style="
                      margin-left: 20px;
                      font-size: 22px;
                      background-color: #8b5ef5;
                      width: 200px;
                      height: 60px;
                      border-radius: 8px;
                      color: #fff;
                      line-height: 60px;
                      text-align: center;
                      cursor: pointer;
                    "
                    @click="buySue"
                  >
                    立即购买
                  </div>
                  <div
                    v-show="buySueShow == 2"
                    style="
                      margin-left: 20px;
                      font-size: 22px;
                      background-color: #8b5ef5;
                      width: 200px;
                      height: 60px;
                      border-radius: 8px;
                      color: #fff;
                      line-height: 60px;
                      text-align: center;
                      cursor: not-allowed;
                    "
                  >
                    请扫码支付
                  </div>
                  <div style="margin-left: 20px; margin-top: 15px">
                    <span style="color: #a6a6a6">支付即视为同意</span>
                    <span style="color: #8b5ef5; cursor: pointer" @click="pdfFile"
                      >《用户协议条例》</span
                    >
                  </div>
                </div>
                <div v-if="zhifuStatus === 1" style="display: flex; align-items: center; margin-top: 10px">
                <div>
                  <div
                    style="
                      margin-left: 20px;
                      display: flex;
                      align-items: baseline;
                      margin-bottom: 15px;
                    "
                  >
                    <div>
                      <img
                        src="@/assets/imgs/zfbZf.png"
                        alt=""
                        style="margin-right: 8px"
                      />
                    </div>
                    <div style="font-size: 16px; color: #171725">
                     支付宝支付，实付：
                    </div>
                    <div style="font-size: 41px; color: #e33f2d">
                      <span style="font-size: 24px">￥</span> {{ money }}
                    </div>
                  </div>
                  <div
                    style="
                      margin-left: 20px;
                      font-size: 22px;
                      background-color: #8b5ef5;
                      width: 200px;
                      height: 60px;
                      border-radius: 8px;
                      color: #fff;
                      line-height: 60px;
                      text-align: center;
                      cursor: pointer;
                    "
                    @click="buySueZfb"
                  >
                    立即购买
                  </div>
                  <div style="margin-left: 20px; margin-top: 15px">
                    <span style="color: #a6a6a6">支付即视为同意</span>
                    <span style="color: #8b5ef5; cursor: pointer" @click="pdfFile"
                      >《用户协议条例》</span
                    >
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import navtop from "@/components/nav-top.vue";
import QRCode from "qrcodejs2";
import {
  getGoodsList,
  getsSaveId,
  getToPay,
  getSuccess,
  addOrder,
  getRefuelBagList,
  getPCToPayByAlipay,
} from "@/api/member";
import { getDictList } from "@/api/information";
import Cookies from "js-cookie";
export default {
  name: "member",
  components: {
    navtop,
  },
  data() {
    return {
      isShow: false,
      goodsList: [],
      value: "",
      num: "",
      ordinaryWord: "",
      seniorWord: "",
      voiceVip: false,
      goodsType: 1,
      title: "",
      dialogVisible: false,
      dialogVisible1: false,
      dialogPay: false,
      name: "",
      memDuration: "",
      id: "",
      isShow1: true,
      orderId: "",
      successData: "",
      price: "",
      term: null,
      goodsList1: [],
      paymentShow: 1,
      money: "",
      paymentShow1: 1,
      paymentShow2: 1,
      qrcode: null,
      qrcode1: null,
      qrcode2: null,
      tmeri: null,
      tmeri1: null,
      tmeri2: null,
      value: "",
      isShowEr: "",
      isShowEr1: "",
      listId: "",
      erweimaData: "",
      buySueShow: 1,
      isShow2: false,
      numberData: this.$route.query.numberData,
      payCode: 1,
      zhifuStatus: 2,
      // ,
    };
  },
  created() {
    console.log("this.$route.query", this.$route.query);
    setTimeout(() => {
      this.getGoods(1);
    }, 500);
    if (this.numberData == 1) {
      setTimeout(() => {
        this.getVoice(true, 2);
      }, 600);
    }
    if (this.numberData == 2) {
      setTimeout(() => {
        this.senior(true, 2);
      }, 600);
    }
    this.questionType();
  },
  mounted() {},
  methods: {
    refresh() {
      // window.open('http://hello.yohelo.com/#/customized', '_blank');
    },
    getVoice(val, num) {
      this.voiceVip = val;
      this.goodsType = num;
      // this.getGoods(1)
      this.senior(false, 1);
    },
    customized() {
      this.$router.push("/customized").catch((error) => error);
    },
    // vip_type
    // 会员列表
    async questionType() {
      let data = {
        code: "vip_type",
      };
      const res = await getDictList(data);
      this.ordinaryWord = res.data.data[0].children[1].dictValue;
      this.seniorWord = res.data.data[0].children[2].dictValue;
    },
    async getGoods(val) {
      const res = await getGoodsList(val, this.goodsType);
      this.goodsList = res.data.data;
    },
    buyNow(id, price, name, memDuration) {
      this.id = id;
      this.price = price;
      this.name = name;
      this.memDuration = memDuration;
      this.dialogVisible = true;
    },
    // 微信支付
    payWx() {
      this.payCode = 2;
      this.getSave(2);
    },
    // 支付宝支付
    payZfb() {
      // this.payCode = 2;
      this.getSaveZfb(1);
    },
    async getSave(val) {
      let goodId = {
        goodIds: this.id,
        teamId: localStorage.getItem("teamId"),
        payMethod: 2,
      };
      const res = await addOrder(goodId);
      this.id = res.data.data.orderId;
      console.log(res.data.data, "---------------------------");
      this.buy(res.data.data.wechatCodePathInWeb);
    },
    async getSaveZfb(val) {
      let goodId = {
        goodIds: this.id,
        teamId: localStorage.getItem("teamId"),
        payMethod: val,
      };
      const res = await addOrder(goodId);
      this.id = res.data.data.orderId;
      console.log(res.data.data.aliPayPageInWeb, ">>>>>>>>>>>>>>>>>>>>>>>>>>");
      window.open(res.data.data.aliPayPageInWeb);
      this.dialogVisible = false;
      this.dialogPay = true;

      this.term = setInterval(() => {
        this.successBuyZfb();
      }, 2000);
    },

    //
    // async buy(value) {
    // let idData = {
    //   orderId: this.id,
    // };
    // const res = await getToPay(idData);
    buy(value) {
      this.isShow1 = false;
      let qrcode = new QRCode("qrcode", {
        width: 210, // 宽
        height: 210, // 高
        text: value, // 二维码内容,也可以直接放接口获取的数据
      });
      this.term = setInterval(() => {
        this.successBuy();
      }, 2000);
    },
    async successBuy() {
      let orderId = this.id;
      const res = await getSuccess(orderId);
      this.successData == res.data.data;
      if (res.data.data == "NOTPAY") {
      } else if (res.data.data == "SUCCESS") {
        this.$router.push("/control").catch((error) => error);
        this.tmeri = null;
        this.tmeri1 = null;
        this.tmeri2 = null;
        this.term = null;
        clearInterval(this.term);
        clearInterval(this.tmeri);
        clearInterval(this.tmeri1);
        clearInterval(this.tmeri2);
        // this.payCode = 3;
        location.reload();
      }
    },
    async successBuyZfb() {
      let orderId = this.id;
      const res = await getSuccess(orderId);
      this.successData == res.data.data;
      if (res.data.data == "NOTPAY") {
      } else if (res.data.data == "SUCCESS") {
        // this.$router.push("/control").catch((error) => error);
        this.tmeri = null;
        this.tmeri1 = null;
        this.tmeri2 = null;
        this.term = null;
        clearInterval(this.term);
        clearInterval(this.tmeri);
        clearInterval(this.tmeri1);
        clearInterval(this.tmeri2);
        // this.payCode = 3;
        // location.reload();
      }
    },
    payOk() {
      this.dialogPay = false;
    },
    payNo() {
      this.dialogPay = false;
    },

    async successBuy(val) {
      let orderId = this.id;
      if (val == 1) {
        this.tmeri1 = null;
        this.tmeri2 = null;
      } else if (val == 2) {
        this.tmeri = null;
        this.tmeri2 = null;
      } else if (val == 3) {
        this.tmeri = null;
        this.tmeri1 = null;
      }
      const res = await getSuccess(orderId);
      this.successData == res.data.data;
      if (res.data.data == "NOTPAY") {
      } else if (res.data.data == "SUCCESS") {
        console.log("12121212121");
        this.tmeri = null;
        this.tmeri1 = null;
        this.tmeri2 = null;
        this.term = null;
        clearInterval(this.term);
        clearInterval(this.tmeri);
        clearInterval(this.tmeri1);
        clearInterval(this.tmeri2);
        this.paymentShow1 = 3;
        this.paymentShow = 3;
        this.paymentShow2 = 3;
        // this.$router.push("/control").catch((error) => error);
        // location.reload();
        this.payCode = 3;
      }
    },

    refuelingBag(vals) {
      this.dialogVisible1 = true;
      if (vals == 1) {
        this.senior1(false, 1);
      } else {
        this.senior1(true, 2);
      }
    },
    senior(value, val) {
      this.isShow = value;
      this.getGoods(val);
    },
    handleClose(done) {
      done();
      const codeList = document.getElementById("qrcode");
      codeList.innerHTML = "";
      const codeList1 = document.getElementById("qrcode1");
      codeList1.innerHTML = "";
      const codeList2 = document.getElementById("qrcode2");
      codeList2.innerHTML = "";
    },

    pdfFile() {
      window.open("https://hello.yohelo.com/protocol/member-vip-protocol.html", "_blank");
      // let data = {
      //     url: "/pdfFile",
      //     width: 1061,
      //     height: 730,
      //     resizable: false,
      //     minWidth: 1061,
      //     // parents:remote.BrowserWindow.getFocusedWindow().id,
      // };
      // ipcRenderer.invoke("open-win", data);
    },
    async getRefuelBag(val) {
      const res = await getRefuelBagList(this.type);
      this.goodsList1 = res.data.data;
      this.goodsList1.forEach((item) => {
        let n = item.price / item.equity;
        item.average = n.toFixed(2);
      });
      this.buyRefueling(this.goodsList1[0], val);
    },

    async buyRefueling(list, val) {
      this.paymentShow1 = 1;
      this.paymentShow = 1;
      this.paymentShow2 = 1;
      this.buySueShow = 1;
      this.valNmu = val;
      this.listId = list.id;
      // this.payment(val)
      this.money = list.price;
    },
    async buySue() {
      this.buySueShow = 2;
      let goodId = {
        goodIds: this.listId,
        teamId: localStorage.getItem("teamId"),
      };
      const res = await getsSaveId(goodId);
      this.id = res.data.data;
      this.payment(this.valNmu);
    },
    async payment(val) {
      let idData = {
        orderId: this.id,
      };

      if (val == 1) {
        this.tmeri1 = null;
        this.tmeri1 = null;
        this.tmeri = null;
        const codeList = document.getElementById("qrcode");
        codeList.innerHTML = "";
        const res = await getToPay(idData);

        this.qrcode = new QRCode("qrcode", {
          width: 140, // 宽
          height: 140, // 高
          text: res.data.data, // 二维码内容,也可以直接放接口获取的数据
          qrCodeSuccess: function (canvas) {
            // 将二维码转为图片
          },
        });

        this.paymentShow = 2;
        if (res.data.code == 200) {
          this.tmeri = setInterval(() => {
            this.successBuy(1);
          }, 3000);
        }
      } else if (val == 2) {
        this.tmeri = null;
        this.tmeri1 = null;
        this.tmeri2 = null;
        const codeList = document.getElementById("qrcode1");
        codeList.innerHTML = "";
        const res = await getToPay(idData);
        this.qrcode1 = new QRCode("qrcode1", {
          width: 140, // 宽
          height: 140, // 高
          text: res.data.data, // 二维码内容,也可以直接放接口获取的数据
          render: "canvas", // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
          qrCodeSuccess: function (canvas) {
            // 将二维码转为图片
          },
        });

        this.paymentShow1 = 2;
        if (res.data.code == 200) {
          this.tmeri1 = setInterval(() => {
            this.successBuy(2);
          }, 3000);
        }
      } else if (val == 3) {
        this.tmeri = null;
        this.tmeri1 = null;
        this.tmeri2 = null;
        const codeList = document.getElementById("qrcode2");
        codeList.innerHTML = "";
        const res = await getToPay(idData);
        this.qrcode2 = new QRCode("qrcode2", {
          width: 140, // 宽
          height: 140, // 高
          text: res.data.data, // 二维码内容,也可以直接放接口获取的数据
          render: "canvas", // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
          qrCodeSuccess: function (canvas) {
            // 将二维码转为图片
          },
        });
        this.paymentShow2 = 2;

        if (res.data.code == 200) {
          this.tmeri2 = setInterval(() => {
            this.successBuy(3);
          }, 3000);
        }
      }
    },

    async buySueZfb(){
      let goodId = {
        goodIds: this.listId,
        teamId: localStorage.getItem("teamId"),
        payMethod: 1,
      };
      const res = await addOrder(goodId);
      this.id = res.data.data.orderId;
      console.log(res.data.data.aliPayPageInWeb, ">>>>>>>>>>>>>>>>>>>>>>>>>>");
      window.open(res.data.data.aliPayPageInWeb);
      this.dialogVisible1 = false;
      this.dialogPay = true;
      this.term = setInterval(() => {
        this.successBuyZfb();
      }, 2000);
    },

    // refuelingBag() {

    // },
    senior1(value, val) {
      this.isShow2 = value;
      if (val == 1) {
        this.paymentShow1 = 1;
        this.type = 1;
        this.getRefuelBag(val);
      } else if (val == 2) {
        this.paymentShow = 1;
        this.type = 2;
        this.getRefuelBag(val);
      } else if (val == 3) {
        this.paymentShow2 = 1;
        this.type = 3;
        this.getRefuelBag(val);
      }
    },
    zhifuStatusHandel(val) {
      this.zhifuStatus = val;
      console.log(this.zhifuStatus); 
    },
  },
};
</script>
<style lang="less" scoped>
::v-deep .el-dialog__header {
  background: linear-gradient(to bottom, #f2ecff, #ffffff);
}
::v-deep .el-dialog__body {
  padding-top: 0px;
}

.userStyle-purchase {
  /* background-color: #1A1A1A; */
  width: 100%;
  height: 100%;
  /* background-image: url("@/assets/imgs/hybj.png"); */
  background-size: 100% 100%;
  background-attachment: fixed;
  overflow: hidden;
  user-select: none;
  /* border: 1px solid #515152; */
}

.qrcode1Style {
  border-radius: 11px;
  overflow: hidden;
  border: 10px solid #fff !important;
  //
}

#qrcode1 {
  img {
    border: 10px solid #fff !important;
  }
}

.qieStyle1 {
  position: relative;
  margin-top: 50px;
  // background-color: #ded1ff;
  // width: 507px;
  // height: 50px;
  border-radius: 30px;
}

.person1 {
  margin-top: 150px;
  width: 90%;
  margin-left: 50px;
  position: relative;
}

.personStyle3 {
  width: 280px;
  height: 80px;
  // background-image: url("@/assets/imgs/wenandi.png");
  // border: solid #8b5ef5 1px;
  border-radius: 8px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-around;
  // background-color: #ded1ff;
  background: linear-gradient(to right, #efecff, #ded1ff);
  cursor: pointer;
  color: #222222;
}

.personStyle4 {
  width: 280px;
  height: 80px;
  background-color: #8b5ef5;
  border: solid #8b5ef5 1px;
  border-radius: 8px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  // margin-top: 5px;
  color: #fff;
  // color: #fff;
}

.personStyle5 {
  margin-left: 25px;
  margin-top: 35px;
  .zhifuWay {
    display: flex;
    align-items: center;
    margin: 28px 0px;
    .title {
      font-family: Source Han Sans SC, Source Han Sans SC;
      font-weight: 400;
      font-size: 14px;
      color: #171725;
    }
    .wx {
      width: 110px;
      height: 27px;
      background: #ffffff;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #dedede;
      margin: 0 10px;
      text-align: center;
      font-family: Source Han Sans SC, Source Han Sans SC;
      font-weight: 400;
      font-size: 12px;
      color: #434343;
      line-height: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        margin-right: 3px;
      }
    }
    .wxXz {
      width: 110px;
      height: 27px;
      background-image: url("@/assets/imgs/xuanzhon.png");
      background-size: 100% 100%;
      margin: 0 10px;
      text-align: center;
      font-family: Source Han Sans SC, Source Han Sans SC;
      font-weight: 400;
      font-size: 12px;
      color: #434343;
      line-height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        margin-right: 3px;
      }
    }
    .zfb {
      width: 110px;
      height: 27px;
      background: #ffffff;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #dedede;
      text-align: center;
      font-family: Source Han Sans SC, Source Han Sans SC;
      font-weight: 400;
      font-size: 12px;
      color: #434343;
      line-height: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        margin-right: 3px;
      }
    }
    .zfbXz {
      width: 110px;
      height: 27px;
      background-image: url("@/assets/imgs/xuanzhon.png");
      background-size: 100% 100%;
      text-align: center;
      font-family: Source Han Sans SC, Source Han Sans SC;
      font-weight: 400;
      font-size: 12px;
      color: #434343;
      line-height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        margin-right: 3px;
      }
    }
  }
}

.userStyle-refueling {
  // background-color: #1A1A1A;
  width: 100%;
  height: 630px;
  background-image: url("@/assets/imgs/Background.png");
  background-size: 100% 100%;
  background-attachment: fixed;
  overflow: hidden;
  user-select: none;
  // border: 1px solid #515152;

  .btn {
    width: 190px;
    padding: 6px 0;
    margin: 10px 0 10px 0;
    font-size: 12px;
    color: white;
    font-weight: 600;
    background-color: #8b5ef5;
    border-radius: 8px;
    border: 0px;
    outline: none;
    cursor: pointer;
    overflow: hidden;
    height: 38px;
  }

  .personWorld {
    font-size: 12px;
    color: #9598a7;
  }
}

.close {
  margin-left: 10px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  width: 30px;
  -webkit-app-region: no-drag;
}

.close:hover {
  border-radius: 5px;
  cursor: pointer;
  background-color: rgba(232, 17, 35, 0.9);
}

.btStyle {
  color: #fff;
  font-size: 18px;
  font-weight: 600px;
}

.yuanStyle {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 5px;
  background-color: #9598a7;
}

.qieStyle {
  position: relative;
  margin-top: 110px;
  width: 410px;
  height: 62px;
  display: flex;
  background-color: #ffffff;
  padding: 5px;
  border-radius: 50px;
  box-shadow: 1px 1px 5px 1px rgb(196, 196, 196);
  // background-color: #fff;
  // width: 200px;
  // height: 50px;
  /* text-align: center; */
}

.person {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  padding-bottom: 10px;
}

.personStyle {
  margin-left: 10px;
  /* background-image: url("@/assets/imgs/gmd.png"); */
  //   border-radius: 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  //   border: solid 1px rgb(196, 196, 196);
  width: 252px;
  height: 360px;
  background-color: #fff;
  box-shadow: 1px 1px 5px 1px rgb(196, 196, 196);
}

.personStyle1 {
  width: 240px;
  height: 110px;
  // background-image: url("@/assets/imgs/huangdi.png");
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.personStyle2 {
  width: 240px;
  height: 110px;
  background-color: #ffedd6;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
}

.btn {
  width: 190px;
  padding: 6px 0;
  margin: 10px 0 10px 0;
  font-size: 12px;
  color: white;
  font-weight: 600;
  background-color: #8b5ef5;
  border-radius: 8px;
  border: 0px;
  outline: none;
  cursor: pointer;
  overflow: hidden;
  height: 38px;
}

.personWorld {
  font-size: 12px;
  color: #9598a7;
}

.buyStyle {
  width: 100%;
  text-align: center;
  padding: 0 20px;
}

.helloStyle {
  color: #333333;
  font-size: 16px;
  font-weight: 600;
}

.codeM {
  width: 100%;
  height: 265px;
  padding-left: 90px;
}
.Payment-Box {
  width: 100%;
  height: 280px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .weixin {
    width: 220px;
    height: 64px;
    background: #28c445;
    border-radius: 8px 8px 8px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    .icon {
      margin-right: 12px;
    }
    .text {
      font-family: Microsoft YaHei UI, Microsoft YaHei UI;
      font-weight: 400;
      font-size: 18px;
      color: #ffffff;
    }
  }
  .zhifubao {
    width: 220px;
    height: 65px;
    background: #5a9ef7;
    border-radius: 8px 8px 8px 8px;
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    .icon {
      margin-right: 12px;
    }
    .text {
      font-family: Microsoft YaHei UI, Microsoft YaHei UI;
      font-weight: 400;
      font-size: 18px;
      color: #ffffff;
    }
  }
}
.PaymentOk {
  width: 100%;
  height: 280px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .payOk {
    width: 240px;
    height: 240px;
    padding-top: 35px;
  }
}
.pay_or_not {
  width: 100%;
  height: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  .payNo {
    width: 140px;
    height: 48px;
    border-radius: 6px 6px 6px 6px;
    border: 1px solid #e9e9e9;
    font-family: Source Han Sans SC, Source Han Sans SC;
    font-weight: 500;
    font-size: 16px;
    color: #0f1114;
    line-height: 44px;
    text-align: center;
    margin-right: 40px;
  }
  .payOk {
    width: 140px;
    height: 48px;
    background: #8b5ef5;
    box-shadow: 0px 0px 4px 1px rgba(15, 12, 84, 0.2);
    border-radius: 6px 6px 6px 6px;
    font-family: Source Han Sans SC, Source Han Sans SC;
    font-weight: 500;
    font-size: 16px;
    color: #ffffff;
    line-height: 44px;
    text-align: center;
  }
}
</style>
