import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '@/views/index.vue';
import Layout from '@/components/layout';
import Centre from '@/views/controllHome/index.vue';
import order from '@/views/project/index.vue';
import projectDetails from '@/views/project/projectDetails.vue';
import WeChat from '@/views/weixin/weChat.vue';
import Team from '@/views/team/index.vue';
import Invite from '@/views/invite/index.vue';
import InviteM from '@/views/invite-m/index.vue';
import Goods from '@/views/goods/index.vue';
import Information from '@/views/information/index.vue';
import Member from '@/views/member/index.vue';
import CloudSpace from '@/views/cloudSpace/cloudSpace.vue';
// cloudSpace
import Authentication from '@/views/authentication/index.vue';
import Customized from '@/views/customized/index.vue';

import PersonalFiles from '@/views/personalFiles/index.vue';
import TeamFiles from '@/views/teamFiles/index.vue';
import dsdh from '@/views/solution/dsdh.vue';
import bdsh from '@/views/solution/bdsh.vue';
import yljk from '@/views/solution/yljk.vue';
import jypx from '@/views/solution/jypx.vue';
import ly from '@/views/solution/ly.vue';
import zlzh from '@/views/solution/zlzh.vue';

import nvwaLive from '@/views/tutorial/nvwa-live.vue';
import nvwaLive_m from '@/views/tutorial/nvwa-live-m.vue';
import viewVideo1 from '@/views/tutorial/viewBox/viewVideo1.vue';
import viewVideo2 from '@/views/tutorial/viewBox/viewVideo2.vue';
import viewVideo3 from '@/views/tutorial/viewBox/viewVideo3.vue';
import viewVideo4 from '@/views/tutorial/viewBox/viewVideo4.vue';
import viewVideo5 from '@/views/tutorial/viewBox/viewVideo5.vue';
import viewVideo6 from '@/views/tutorial/viewBox/viewVideo6.vue';
import viewVideo7 from '@/views/tutorial/viewBox/viewVideo7.vue';
import viewVideo8 from '@/views/tutorial/viewBox/viewVideo8.vue';
import viewVideo9 from '@/views/tutorial/viewBox/viewVideo9.vue';
import viewVideo10 from '@/views/tutorial/viewBox/viewVideo10.vue';
import viewVideo11 from '@/views/tutorial/viewBox/viewVideo11.vue';

import pushcode from '@/views/tutorial/pushcode.vue';
import virtualCamer from '@/views/tutorial/virtual-camer.vue';
import liveNumber from '@/views/tutorial/live-number.vue';

import download from '@/views/help/download.vue';
import androidApp from '@/views/help/androidApp.vue';

import about from '@/views/about/index.vue';

import nvwa from '@/views/product/nvwa.vue';

import dyy from '@/views/service/dyy.vue';
import dyyM from '@/views/service/dyy-m.vue';

import { wxChatShare } from '@/utils/weixin.js'; // 引入wechat.js

// customized
Vue.use(VueRouter);

// 解决重复点击路由报错的BUG
// 下面这段代码主要解决这个问题 ：Uncaught (in promise) Error: Redirected when going from "/login" to "/index" via a navigation guard.
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/invite',
    name: 'invite',
    component: Invite
  },
  {
    path: '/invite-m',
    name: 'invite-m',
    component: InviteM,
    meta: {
      title: 'Nvwa智播-团队成员邀请',
      desc: '构建数字人、语音及虚拟场景等短视频&直播多场景、多平台同步智能直播',
      imgUrl: process.env.VUE_APP_URL + '/static/imgs/icon.png'
    }
  },
  {
    path: '/solution/dsdh',
    name: 'dsdh',
    component: dsdh
  },
  {
    path: '/solution/bdsh',
    name: 'bdsh',
    component: bdsh
  },
  {
    path: '/solution/yljk',
    name: 'yljk',
    component: yljk
  },
  {
    path: '/solution/jypx',
    name: 'jypx',
    component: jypx
  },
  {
    path: '/solution/ly',
    name: 'ly',
    component: ly
  },
  {
    path: '/solution/zlzh',
    name: 'zlzh',
    component: zlzh
  },
  {
    path: '/tutorial/nvwaLive',
    name: 'nvwaLive',
    component: nvwaLive
  },
  {
    path: '/tutorial/nvwaLive-m',
    name: 'nvwaLive-m',
    component: nvwaLive_m,
    meta: {
      title: 'Nvwa智播App-教程',
      desc: '构建数字人、语音及虚拟场景等短视频&直播多场景、多平台同步智能直播',
      imgUrl: process.env.VUE_APP_URL + '/static/imgs/icon.png'
    }
  },
  {
    path: '/tutorial/viewBox/viewVideo1',
    name: 'viewVideo1',
    component: viewVideo1,
    meta: {
      title: '如何进行场景搭建',
      desc: '如何进行场景搭建',
      imgUrl: process.env.VUE_APP_URL + '/static/imgs/icon.png'
    }
  },
  {
    path: '/tutorial/viewBox/viewVideo2',
    name: 'viewVideo2',
    component: viewVideo2,
    meta: {
      title: '如何编辑多个直播内容下的图层与素材',
      desc: '如何编辑多个直播内容下的图层与素材',
      imgUrl: process.env.VUE_APP_URL + '/static/imgs/icon.png'
    }
  },
  {
    path: '/tutorial/viewBox/viewVideo3',
    name: 'viewVideo3',
    component: viewVideo3,
    meta: {
      title: '如何编辑图层与素材',
      desc: '如何编辑图层与素材',
      imgUrl: process.env.VUE_APP_URL + '/static/imgs/icon.png'
    }
  },
  {
    path: '/tutorial/viewBox/viewVideo4',
    name: 'viewVideo4',
    component: viewVideo4,
    meta: {
      title: '如何进行团队共创',
      desc: '如何进行团队共创',
      imgUrl: process.env.VUE_APP_URL + '/static/imgs/icon.png'
    }
  },
  {
    path: '/tutorial/viewBox/viewVideo5',
    name: 'viewVideo5',
    component: viewVideo5,
    meta: {
      title: '如何创建数字人视频',
      desc: '如何创建数字人视频',
      imgUrl: process.env.VUE_APP_URL + '/static/imgs/icon.png'
    }
  },
  {
    path: '/tutorial/viewBox/viewVideo6',
    name: 'viewVideo6',
    component: viewVideo6,
    meta: {
      title: '如何添加多个内容片段',
      desc: '如何添加多个内容片段',
      imgUrl: process.env.VUE_APP_URL + '/static/imgs/icon.png'
    }
  },
  {
    path: '/tutorial/viewBox/viewVideo7',
    name: 'viewVideo7',
    component: viewVideo7,
    meta: {
      title: '如何创建数字语音',
      desc: '如何创建数字语音',
      imgUrl: process.env.VUE_APP_URL + '/static/imgs/icon.png'
    }
  },
  {
    path: '/tutorial/viewBox/viewVideo8',
    name: 'viewVideo8',
    component: viewVideo8,
    meta: {
      title: '如何定制数字人/数字声音',
      desc: '如何定制数字人/数字声音',
      imgUrl: process.env.VUE_APP_URL + '/static/imgs/icon.png'
    }
  },
  {
    path: '/tutorial/viewBox/viewVideo9',
    name: 'viewVideo9',
    component: viewVideo9,
    meta: {
      title: '如何进行直播',
      desc: '如何进行直播',
      imgUrl: process.env.VUE_APP_URL + '/static/imgs/icon.png'
    }
  },
  {
    path: '/tutorial/viewBox/viewVideo10',
    name: 'viewVideo10',
    component: viewVideo10,
    meta: {
      title: '如何开启虚实结合',
      desc: '如何开启虚实结合',
      imgUrl: process.env.VUE_APP_URL + '/static/imgs/icon.png'
    }
  },
  {
    path: '/tutorial/viewBox/viewVideo11',
    name: 'viewVideo11',
    component: viewVideo11,
    meta: {
      title: '如何邀请团队成员',
      desc: '如何邀请团队成员',
      imgUrl: process.env.VUE_APP_URL + '/static/imgs/icon.png'
    }
  },
  {
    path: '/tutorial/pushcode',
    name: 'pushcode',
    component: pushcode
  },
  {
    path: '/tutorial/virtualCamer',
    name: 'virtualCamer',
    component: virtualCamer
  },
  {
    path: '/tutorial/liveNumber',
    name: 'liveNumber',
    component: liveNumber
  },
  {
    path: '/help/download',
    name: 'download',
    component: download,
    meta: {
      title: 'Nvwa智播（短视频、虚拟直播-数字人）下载',
      desc: '构建数字人、语音及虚拟场景等短视频&直播多场景、多平台同步智能直播',
      imgUrl: process.env.VUE_APP_URL + '/static/imgs/icon.png'
    }
  },
  {
    path: '/help/androidApp',
    name: 'androidApp',
    component: androidApp,
    meta: {
      title: 'Nvwa智播（短视频、虚拟直播-数字人）Android下载',
      desc: '构建数字人、语音及虚拟场景等短视频&直播多场景、多平台同步智能直播',
      imgUrl: process.env.VUE_APP_URL + '/static/imgs/icon.png'
    }
  },
  {
    path: '/about',
    name: 'about',
    component: about
  },
  {
    path: '/product/nvwa',
    name: 'nvwa',
    component: nvwa
  },
  {
    path: '/service/dyy',
    name: 'dyy',
    component: dyy,
    meta: {
      title: 'Nvwa智播代运营服务',
      desc: '助力商家从引流获客、品牌声量提升、客户服务、自媒体经营建设等全维度为企业营销发展赋能！',
      imgUrl: process.env.VUE_APP_URL + '/static/imgs/codedyy.png'
    }
  },
  {
    path: '/service/dyy-m',
    name: 'dyy-m',
    component: dyyM,
    meta: {
      title: 'Nvwa智播代运营服务',
      desc: '助力商家从引流获客、品牌声量提升、客户服务、自媒体经营建设等全维度为企业营销发展赋能！',
      imgUrl: process.env.VUE_APP_URL + '/static/imgs/codedyy.png'
    }
  },
  {
    path: '/control',
    name: 'Layout',
    component: Layout,
    children: [
      {
        path: '/',
        name: 'Centre',
        component: Centre
      },
      // {
      //   path: '/space',
      //   name: 'space',
      //   // component: Layout,

      // },
      {
        path: '/personalFiles',
        name: 'PersonalFiles',
        component: PersonalFiles
      },
      {
        path: '/teamFiles',
        name: 'TeamFiles',
        component: TeamFiles
      },
      {
        path: '/order',
        name: 'order',
        component: order
      },
      {
        path: '/order/projectDetails',
        name: 'projectDetails',
        component: projectDetails
      },
      {
        path: '/team',
        name: 'team',
        component: Team
      },
      {
        path: '/goods',
        name: 'Goods',
        component: Goods
      },
      {
        path: '/information',
        name: 'Information',
        component: Information
      }
    ]
  },
  {
    path: '/weChat',
    name: 'weChat',
    component: WeChat
  },
  {
    path: '/member',
    name: 'member',
    component: Member
  },
  {
    path: '/cloudSpace',
    name: 'cloudSpace',
    component: CloudSpace
  },
  //
  {
    path: '/customized',
    name: 'customized',
    component: Customized
  },
  {
    path: '/authentication',
    name: 'authentication',
    component: Authentication
  }
];

const router = new VueRouter({
  mode: 'hash',
  // base: process.env.VUE_APP_URL,
  routes
});

// 设置全局前置守卫
router.beforeEach((to, from, next) => {
  // 可以在这里进行路由守卫的逻辑处理
  console.log('全局前置守卫', to);

  // 路由里面有title如没有设置默认值
  if (to.meta.title) {
    document.title = to.meta.title;
  } else {
    document.title = 'Hello-数字虚拟化AIGC-河络信息科技';
  }

  let _param = {};
  if (to.fullPath.slice(0, -1) === "/tutorial/viewBox/viewVideo") {
    _param = {
      url: window.location.href, // 当前页面url
      title: 'Nvwa智播App-教程', // 分享数据配置
      desc: to.meta.desc, // 分享数据配置
      link: process.env.VUE_APP_URL + '/#' + to.path,
      imgUrl: to.meta.imgUrl, // 分享数据配置 －－ 全路径
      type: 'link' // 分享类型,music、video或link，不填默认为link
    };
  } else {
    // 路由meta里面有title、desc、imgUrl 进入微信二次分享方法
    if (to.meta.title && to.meta.desc && to.meta.imgUrl) {
      _param = {
        url: window.location.href, // 当前页面url
        title: to.meta.title, // 分享数据配置
        desc: to.meta.desc, // 分享数据配置
        link: process.env.VUE_APP_URL + '/#' + to.path,
        imgUrl: to.meta.imgUrl, // 分享数据配置 －－ 全路径
        type: 'link' // 分享类型,music、video或link，不填默认为link
      };
    } else {
      _param = {
        url: window.location.href,
        title: 'Hello-数字虚拟化AIGC-河络信息科技',
        desc: '帮助商户/企业从引流获客、品牌声量提升、客户服务、自媒体经营建设等全维度为企业营销发展赋能，为企业数字营销打造数字虚拟化劳动力（数字人/虚拟场景等），降本增效！',
        link: process.env.VUE_APP_URL + '/#' + to.path,
        imgUrl: process.env.VUE_APP_URL + '/static/imgs/icon.png',
        type: 'link'
      };
    }
  }
  console.log(_param, '_param_param_param_param》》》》》》》》》》》》》》》》》》》》》》');
  wxChatShare(_param);

  // 确保要调用next()来resolve这个钩子
  next();
});

export default router;
