<template>
  <div>
    <el-header>
      <navtop></navtop>
    </el-header>
    <div
      style="
        width: 100%;
        height: 160px;
        background: linear-gradient(to right, #9facff, #c4adf9);
        font-weight: 600;
        color: #434343;
        font-size: 32px;
        text-align: center;
        line-height: 160px;
      "
    >
      数字人定制
    </div>
    <div class="userStyle-customized" @click.stop="asds">
      <div
        style="padding: 0px 15px; width: 100%; display: flex; justify-content: center"
        @click.stop="customer1"
      >
        <div style="width: 563px">
          <div class="customizedLefTop">
            <div
              v-show="postureNum == 0"
              style="
                width: 259px;
                height: 460px;
                border-radius: 8px;
                position: relative;
                overflow: hidden;
              "
            >
              <video
                ref="video2"
                :src="stand"
                alt=""
                width="100%"
                height="100%"
                :controls="controls"
                controlsList="nodownload"
                @ended="audioEnded"
              ></video>
              <div
                style="
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  top: 0;
                  cursor: pointer;
                "
                @click.stop="playJc"
                v-show="playShow == true"
              >
                <div style="margin-top: 186px; margin-left: 120px">
                  <img
                    src="@/assets/imgs/bofanganniu.png"
                    alt=""
                    width="30px"
                    height="30px"
                    style="margin-top: 12px; margin-left: 2px"
                  />
                </div>
              </div>
            </div>
            <!-- stand -->
            <div
              v-show="postureNum == 1"
              style="
                width: 259px;
                height: 460px;
                border-radius: 8px;
                position: relative;
                overflow: hidden;
              "
            >
              <video
                ref="video3"
                :src="sit"
                alt=""
                width="100%"
                height="100%"
                :controls="controls1"
                controlsList="nodownload"
                @ended="audioEnded1"
              ></video>
              <div
                style="
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  top: 0;
                  cursor: pointer;
                "
                @click.stop="playJc1"
                v-show="playShow1 == true"
              >
                <div style="margin-top: 186px; margin-left: 120px">
                  <img
                    src="@/assets/imgs/bofanganniu.png"
                    alt=""
                    width="30px"
                    height="30px"
                    style="margin-top: 12px; margin-left: 2px"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="customizedLefButton">
            <div :class="postureNum == 0 ? 'but7' : 'but8'" @click.stop="submitS">
              视频示例：站姿
            </div>
            <div
              style="margin-left: 10px"
              :class="postureNum == 1 ? 'but7' : 'but8'"
              @click.stop="submitForm"
            >
              视频示例：坐姿
            </div>
          </div>
        </div>
        <!-- position: relative; flex: 1; -->
        <div style="margin-left: 30px; width: 750px">
          <div class="customizedRightT">
            <!-- customizedRightX1 -->
            <div
              :class="
                index == 0
                  ? 'customizedRightX'
                  : index == 1
                  ? 'customizedRightX1'
                  : index == 2
                  ? 'customizedRightX2'
                  : ''
              "
              v-for="(item, index) in remarksList"
              :key="index"
            >
              <div
                style="display: flex; align-items: center; justify-content: space-between"
              >
                <div>
                  <!-- <img v-if="item.dictKey == 0" src="@/assets/imgs/zhuanyeban.png" alt="">
                                <img v-if="item.dictKey == 1" src="@/assets/imgs/gaojiban.png" alt="">
                                <img v-if="item.dictKey == 2" src="@/assets/imgs/qiyeban.png" alt=""> -->
                  <div v-if="item.dictKey == 0" class="wordJb" style="color: #a35d3c">
                    {{ item.dictValue }}
                  </div>
                  <div v-if="item.dictKey == 1" class="wordJb" style="color: #9e5f3e">
                    {{ item.dictValue }}
                  </div>
                  <div v-if="item.dictKey == 2" class="wordJb" style="color: #ffffff">
                    {{ item.dictValue }}
                  </div>
                </div>
                <div style="color: #a35d3c; font-size: 12px">形象声音永久有效</div>
                <!-- <div style="color: #E1D5DF;font-size: 12px;"></div> -->
              </div>
              <div
                style="display: flex; justify-content: space-between; margin-top: 10px"
              >
                <div class="customizedDing" v-for="(list, ind) in item.list" :key="ind">
                  <div style="margin-top: 5px" v-show="list.customizedContent == 1">
                    <img src="@/assets/imgs/xingxiangdingzhi.png" alt="" />
                  </div>
                  <div style="margin-top: 5px" v-show="list.customizedContent == 2">
                    <img src="@/assets/imgs/shengyindingzhi.png" alt="" />
                  </div>
                  <div style="color: #434343; font-size: 14px; margin-left: 5px">
                    {{ list.dictValue }}
                  </div>
                  <div
                    style="
                      color: #e3652d;
                      width: 120px;
                      text-align: center;
                      font-weight: 600;
                    "
                  >
                    <span style="font-size: 10px">￥</span>
                    {{ list.price }}
                  </div>
                  <div style="cursor: pointer">
                    <div
                      v-show="list.checked == false"
                      style="
                        background-color: rgba(67, 67, 67, 0.8);
                        width: 15px;
                        height: 15px;
                      "
                      @click.stop="checkedEven(list, item)"
                    ></div>
                    <div
                      v-show="list.checked != false"
                      @click.stop="checkedEve1(list)"
                      style="
                        color: #fff;
                        background-color: #8b5ef5;
                        width: 15px;
                        height: 15px;
                      "
                    >
                      <i class="el-icon-check"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div style="color: #b0885f; font-size: 12px; margin-top: 10px">
                {{ item.world }}
              </div>
              <div style="color: #a35d3c; font-size: 14px; margin-top: 10px">
                赠送：{{ item.time }}分钟时长
              </div>
            </div>
          </div>
          <div class="customizedRightZ">
            <el-checkbox v-model="checked7" @change="weiG"></el-checkbox>
            <span style="color: #606266; font-size: 10px">阅读相关协议和政策</span>
            <span style="color: #8b5ef5; font-size: 10px; cursor: pointer"
              >《数字人形象定制规范》</span
            >
            <span style="color: #8b5ef5; font-size: 10px; cursor: pointer"
              >《声音复刻规范》</span
            >
          </div>
          <div class="customizedRightB">
            <div :class="weiShow == 1 ? 'but71' : 'but7'" @click.stop="oneLj">
              立即定制
            </div>
          </div>
          <!-- <div style="position: absolute;right: -15px;bottom: -10px;cursor: pointer;" @click.stop="customer">
                    <img src="@/assets/imgs/lainxikefu.png" alt="" width="46px" height="47px">
                </div>
                <div style="position:absolute;right: 35px;bottom: -5px;width: 160px;height: 175px;padding: 10px;background-color: #fff;
                    border-radius: 10px;display: flex;flex-direction: column;align-items: center;"
                    v-show="customerShow == true">
                    <div>
                        <img src="@/assets/imgs/kefu.png" alt="" width="140px" height="140px">
                    </div>
                    <div style="font-size: 14px;margin-top: -3px;">
                        联系客服 了解更多
                    </div>
                </div> -->
        </div>
        <div
          style="position: fixed; right: 0px; bottom: 20%; cursor: pointer"
          @click.stop="customer"
        >
          <img src="@/assets/imgs/lainxikefu.png" alt="" width="46px" height="47px" />
        </div>
        <div
          style="
            position: fixed;
            right: 45px;
            bottom: 20%;
            width: 160px;
            height: 175px;
            padding: 10px;
            background-color: #fff;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
          "
          v-show="customerShow1 == true"
        >
          <div>
            <img src="@/assets/imgs/kefu.png" alt="" width="140px" height="140px" />
          </div>
          <div style="font-size: 14px; margin-top: -3px">联系客服 了解更多</div>
        </div>
        <!-- <div style="margin-top: 5px;width: 100%; text-align: center;">
                <el-button class="but7" type="primary" size="small" @click.stop="closeBtn">关闭</el-button>
            </div> -->
      </div>
      <el-dialog
        title="数字人定制"
        :visible.sync="dialogVisible1"
        width="870px"
        :before-close="handleClose"
      >
        <div class="userStyle-refueling19">
          <div class="person1">
            <div class="customizedRightX1">
              <div>
                <div style="font-size: 12px; color: #171725">
                  <span class="wordBj" style="">{{ dictValue }}</span>
                  <span style="margin-left: 10px">{{ dictValue1 }}</span>
                </div>
                <div style="font-size: 12px; color: #171725; margin-top: 10px">
                  {{ content }}
                </div>
                <div style="font-size: 12px; color: #171725; margin-top: 10px">
                  赠送{{ duration }}分钟时长，数字人永久有效
                </div>
              </div>
              <div style="color: #e3652d; font-size: 32px; font-weight: 600">
                ￥{{ price }}
              </div>
            </div>
            <div class="personStyle12">
              <div style="font-size: 16px; color: #171725; font-weight: 600">
                规则说明
              </div>
              <div style="font-size: 12px; color: #171725; margin-top: 10px">
                1.费用包括定制数字人的永久使用权
              </div>
              <div style="font-size: 12px; color: #171725; margin-top: 10px">
                2.付费后我们的客服将在1-3个工作日内联系您提供定制的形象视频和音频
              </div>
              <div style="display: flex; align-items: center; margin-top: 50px">
                <div
                  style="
                    width: 160px;
                    height: 160px;
                    background-color: #fff;
                    border-radius: 11px;
                  "
                >
                  <div v-show="paymentShow1 == 1">
                    <img
                      src="@/assets/imgs/zferweima.png"
                      alt=""
                      width="100%"
                      height="100%"
                    />
                  </div>
                  <div
                    class="qrcode1Style"
                    style=""
                    id="qrcode1"
                    v-show="paymentShow1 == 2"
                  ></div>
                  <div v-show="paymentShow1 == 3">
                    <img src="@/assets/imgs/cg.png" alt="" />
                    <div style="font-size: 14px; color: #fff; margin-left: 60px">
                      支付成功
                    </div>
                  </div>
                </div>

                <div>
                  <div
                    style="
                      margin-left: 20px;
                      display: flex;
                      align-items: baseline;
                      margin-bottom: 15px;
                    "
                  >
                    <div>
                      <img
                        src="@/assets/imgs/wxsmzf.png"
                        alt=""
                        style="margin-right: 8px"
                      />
                    </div>
                    <div style="font-size: 16px; color: #171725">
                      微信扫码支付，实付：
                    </div>
                    <div style="font-size: 41px; color: #e33f2d">
                      <span style="font-size: 20px">￥</span>
                      <span>{{ price }}</span>
                    </div>
                  </div>
                  <div
                    v-show="buySueShow == 1"
                    style="
                      margin-left: 20px;
                      font-size: 22px;
                      background-color: #8b5ef5;
                      width: 200px;
                      height: 60px;
                      border-radius: 8px;
                      color: #fff;
                      line-height: 60px;
                      text-align: center;
                      cursor: pointer;
                    "
                    @click.stop="buySue"
                  >
                    立即购买
                  </div>
                  <div
                    v-show="buySueShow == 2"
                    style="
                      margin-left: 20px;
                      font-size: 22px;
                      background-color: #8b5ef5;
                      width: 200px;
                      height: 60px;
                      border-radius: 8px;
                      color: #fff;
                      line-height: 60px;
                      text-align: center;
                      cursor: not-allowed;
                    "
                  >
                    请扫码支付
                  </div>
                  <div style="margin-left: 20px; margin-top: 15px">
                    <span style="color: #171725">支付即视为同意</span>
                    <span style="color: #8b5ef5; cursor: pointer" @click.stop="pdfFile"
                      >《用户协议条例》</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import navtop from "@/components/nav-top.vue";
import { getCustomizedList, getToPay, getsSaveId, getSuccess } from "@/api/member";
import QRCode from "qrcodejs2";
import { getDictList } from "@/api/member";
import Cookies from "js-cookie";
// import { ipcRenderer } from "electron";
// const remote = require('@electron/remote')
// const fs = require("fs");
export default {
  name: "sourceP",
  components: {
    navtop,
  },
  data() {
    return {
      tableData: [],
      multipleSelection: [],
      ossUrl: localStorage.getItem("oss"),
      postureNum: 0,
      checked1: false,
      // checked2: true,
      // checked3: true,
      // checked4: true,
      // checked5: true,
      // checked6: true,
      checked7: false,
      remarksList: [],
      imageList: [],
      voiceList: [],
      list1: [],
      list2: [],
      list3: [],
      checkedF: false,
      checkedId: "",
      itemId: "",
      sit: "https://hello.yohelo.com/case/sit.mp4",
      stand: "https://hello.yohelo.com/case/stand.mp4",
      playShow: true,
      controls: null,
      playShow1: true,
      controls1: null,
      customerShow: false,
      dialogVisible1: false,
      customerShow1: false,
      dictValue: "",
      price: "",
      content: "",
      dictValue1: "",
      ids: "",
      duration: "",
      teamId: localStorage.getItem("teamId"),

      isShow: false,
      goodsList: [],
      type: 1,
      goodId: "",
      id: "",
      paymentShow: 1,
      money: "",
      paymentShow1: 1,
      qrcode: null,
      qrcode1: null,
      tmeri: null,
      tmeri1: null,
      value: "",
      isShowEr: "",
      isShowEr1: "",
      listId: "",
      erweimaData: "",
      buySueShow: 1,
      weiShow: 1,
    };
  },
  created() {
    setTimeout(() => {
      this.getGoods();
      this.questionType();
      this.questionType1();
    }, 500);
    // ipcRenderer.on('send-data', (event, arg) => {
    //     this.value = arg.val
    // })
    // ipcRenderer.on('invoicingEnd', (event, message) => {
    //     this.getGoods()
    // })
  },
  mounted() {
    // let acce = process.cwd() + "\\public\\config.json"
    // const data = fs.readFileSync(acce, 'utf8');
    // this.sit = JSON.parse(data).sit
    // this.stand = JSON.parse(data).stand
  },
  methods: {
    weiG() {
      if (this.checked7 == false) {
        this.weiShow = 1;
      } else {
        this.remarksList.forEach((item) => {
          item.list.forEach((items) => {
            if (items.checked == true) {
              this.weiShow = 2;
            }
          });
        });
      }
    },
    asds() {
      this.customerShow1 = false;
    },
    pdfFile() {
      window.open("https://hello.yohelo.com/protocol/member-vip-protocol.html", "_blank");
    },
    playJc() {
      this.customerShow = false;
      this.controls = "controls";
      this.playShow = false;
      this.$refs.video2.play();
    },
    audioEnded() {
      this.playShow = true;
      this.controls = null;
    },
    playJc1() {
      this.customerShow = false;
      this.controls1 = "controls";
      this.playShow1 = false;
      this.$refs.video3.play();
    },
    audioEnded1() {
      this.playShow1 = true;
      this.controls1 = null;
    },
    customer() {
      this.customerShow1 = true;
    },
    customer1() {
      this.customerShow = false;
    },
    oneLj() {
      let s = false;
      this.remarksList.forEach((item) => {
        item.list.forEach((items) => {
          if (items.checked == true) {
            s = true;
          }
        });
      });

      // return
      if (this.checked7 == false || s == false) {
        return;
      }

      this.remarksList.forEach((item) => {});
      this.dialogVisible1 = true;
      this.customerShow = false;
      this.dictValue = "";
      this.price = 0;
      this.content = "";
      this.dictValue1 = "";
      this.id = "";
      let show = 0;
      this.duration = 0;

      this.remarksList.forEach((item) => {
        item.list.forEach((items) => {
          if (items.checked == true) {
            show = 1;
            this.dictValue = item.dictValue;
            this.price = this.price + Number(items.price);
            this.duration = this.duration + Number(items.duration);
            this.content = this.content + "," + items.content;
            this.dictValue1 = this.dictValue1 + "+" + items.dictValue;
            this.id = this.id + "," + items.id;
          }
        });
      });

      let a = this.id;
      this.dictValue1 = this.dictValue1.substr(1);
      this.id = this.id.substr(1);

      this.content = this.content.substr(1).slice(0, -1);
      // this.content = this.content.substr(this.content.length);

      if (show == 1) {
        if (this.checked7 == false) {
          this.$message({
            type: "warning",
            message: "请勾选相关协议和政策!!!",
            duration: 1500,
          });
        } else {
          this.dialogVisible1 = true;
          // ipcRenderer.invoke("window-close");
          // let data = {
          //     width: 870,
          //     height: 630,
          //     url: "/customizedBuy",
          //     resizable: false,
          //     minWidth: 870,
          //     sendData: {
          //         dictValue: dictValue,
          //         price: price,
          //         content: content,
          //         dictValue1: dictValue1,
          //         id: id,
          //         duration: duration
          //     },
          // };
          // ipcRenderer.invoke("open-win", data);
        }
      } else {
        this.$message({
          type: "warning",
          message: "请选择定制类型!!!",
          duration: 1500,
        });
      }
    },
    checkedEven(list, item) {
      this.customerShow = false;
      this.itemId = item.id;
      if (list.customizedType == 0) {
        this.remarksList.forEach((item) => {
          item.list.forEach((items) => {
            if (list.id == items.id) {
              items.checked = true;
            }
            if (items.customizedType == 1 || items.customizedType == 2) {
              items.checked = false;
            }
          });
        });
      }
      if (list.customizedType == 1) {
        this.remarksList.forEach((item) => {
          item.list.forEach((items) => {
            if (list.id == items.id) {
              items.checked = true;
            }
            if (items.customizedType == 0 || items.customizedType == 2) {
              items.checked = false;
            }
          });
        });
      }
      if (list.customizedType == 2) {
        this.remarksList.forEach((item) => {
          item.list.forEach((items) => {
            if (list.id == items.id) {
              items.checked = true;
            }
            if (items.customizedType == 1 || items.customizedType == 0) {
              items.checked = false;
            }
          });
        });
      }
      this.$forceUpdate();
      if (this.checked7 == false) {
        this.weiShow = 1;
      } else {
        this.remarksList.forEach((item) => {
          item.list.forEach((items) => {
            if (items.checked == true) {
              this.weiShow = 2;
            }
          });
        });
      }
    },
    checkedEve1(list) {
      this.customerShow = false;
      this.remarksList.forEach((item) => {
        item.list.forEach((items) => {
          if (list.id == items.id) {
            items.checked = false;
          }
        });
      });
      this.$forceUpdate();
      if (this.checked7 == false) {
        this.weiShow = 1;
      } else {
        this.remarksList.forEach(
          (item) => {
            let a = item.list.every((x) => x.checked == true);

            if (a == false) {
              this.weiShow = 1;
            }
          }
          //
        );
      }
    },
    async questionType() {
      let data = {
        code: "customized_content",
      };
      const res = await getDictList(data);
      this.voiceList = res.data.data[0].children;
    },
    async questionType1() {
      let data = {
        code: "customized_type",
      };
      const res = await getDictList(data);
      this.imageList = res.data.data[0].children;
      if (res.data.code === 200) {
        const res1 = await getCustomizedList();

        this.list1 = [];
        this.list2 = [];
        this.list3 = [];
        res1.data.data.forEach((item) => {
          item.price = parseInt(item.price);
          if (item.customizedType == 0) {
            this.list1.push(item);
          }
          if (item.customizedType == 1) {
            this.list2.push(item);
          }
          if (item.customizedType == 2) {
            this.list3.push(item);
          }
        });
        // 11111
        let world1 = "";
        let world2 = "";
        let world3 = "";
        let time1 = 0;
        let time2 = 0;
        let time3 = 0;
        this.list1.forEach((item) => {
          world1 = world1 + "  " + item.content;
          time1 = time1 + Number(item.duration);
          item.checked = false;
          this.voiceList.forEach((items) => {
            if (item.customizedContent == items.dictKey) {
              item.dictValue = items.dictValue;
            }
          });
        });
        // 11111
        this.list2.forEach((item) => {
          world2 = world2 + "  " + item.content;
          time2 = time2 + Number(item.duration);
          item.checked = false;
          this.voiceList.forEach((items) => {
            if (item.customizedContent == items.dictKey) {
              item.dictValue = items.dictValue;
            }
          });
        });
        // 11111
        this.list3.forEach((item) => {
          world3 = world3 + "  " + item.content;
          time3 = time3 + Number(item.duration);
          item.checked = false;
          this.voiceList.forEach((items) => {
            if (item.customizedContent == items.dictKey) {
              item.dictValue = items.dictValue;
            }
          });
        });

        // .dictKey
        this.remarksList = this.imageList;
        // 11111
        if (this.list1.length > 0) {
          this.remarksList.forEach((item) => {
            if (item.dictKey == 0) {
              item.list = this.list1;
              item.world = world1;
              item.time = time1;
            }
          });
        } else {
          this.remarksList = this.remarksList.filter((item) => {
            return item.elementType != 0;
          });
        }
        // 11111
        if (this.list2.length > 0) {
          this.remarksList.forEach((item) => {
            if (item.dictKey == 1) {
              item.list = this.list2;
              item.world = world2;
              item.time = time2;
            }
          });
        } else {
          this.remarksList = this.remarksList.filter((item) => {
            return item.dictKey != 1;
          });
        }
        // 11111
        if (this.list3.length > 0) {
          this.remarksList.forEach((item) => {
            if (item.dictKey == 2) {
              item.list = this.list3;
              item.world = world3;
              item.time = time3;
            }
          });
        } else {
          this.remarksList = this.remarksList.filter((item) => {
            return item.dictKey != 2;
          });
        }
      }
    },
    async getGoods() {},
    submitS() {
      this.$refs.video3.pause();
      this.playShow = true;
      this.playShow1 = true;
      this.controls1 = null;
      this.customerShow = false;
      this.postureNum = 0;
    },
    submitForm() {
      this.$refs.video2.pause();
      this.playShow = true;
      this.playShow1 = true;
      this.controls = null;
      this.customerShow = false;
      this.postureNum = 1;
    },
    closeBtn() {
      // ipcRenderer.invoke("window-close");
    },
    async buySue() {
      this.buySueShow = 2;
      let goodId = {
        goodIds: this.id,
        teamId: this.teamId,
      };
      const res = await getsSaveId(goodId);
      this.id = res.data.data;
      this.payment();
    },
    async payment() {
      let idData = {
        orderId: this.id,
      };
      this.tmeri1 = null;
      const codeList = document.getElementById("qrcode1");
      codeList.innerHTML = "";
      const res = await getToPay(idData);
      this.qrcode1 = new QRCode("qrcode1", {
        width: 140, // 宽
        height: 140, // 高
        text: res.data.data, // 二维码内容,也可以直接放接口获取的数据
        render: "canvas", // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
        qrCodeSuccess: function (canvas) {
          // 将二维码转为图片
        },
      });
      this.paymentShow1 = 2;
      if (res.data.code == 200) {
        this.tmeri1 = setInterval(() => {
          this.successBuy(2);
        }, 3000);
      }
    },
    handleClose(done) {
      this.tmeri1 = null;
      clearInterval(this.tmeri);
      location.reload();
      done();
    },
    async successBuy(val) {
      let orderId = this.id;

      this.tmeri1 = null;
      const res = await getSuccess(orderId);
      this.successData == res.data.data;
      if (res.data.data == "NOTPAY") {
      } else if (res.data.data == "SUCCESS") {
        this.tmeri1 = null;
        clearInterval(this.tmeri);
        this.paymentShow1 = 3;
      }
    },
    refuelingBag() {},
    closeBtn() {
      ipcRenderer.invoke("window-close");
    },
  },
};
</script>
<style lang="less">
.userStyle-customized {
  //   background-color: #fff;
  width: 100%;
  height: 100%;
  overflow: hidden;
  user-select: none;
  //   border: 1px solid #fff;
  position: absolute;
  top: 190px;
  .userStyle-refueling19 {
    // background-color: ;
    width: 100%;
    height: 100%;
    // background-image: url('@/assets/imgs/dingzhibj.png');
    // background-size: 100% 100%;
    // background-attachment: fixed;
    overflow: hidden;
    user-select: none;
    // border: 1px solid #515152;

    .qrcode1Style {
      border-radius: 11px;
      overflow: hidden;
      border: 10px solid #fff !important;
      //
    }

    #qrcode1 {
      img {
        border: 10px solid #fff !important;
      }
    }
    .wordBj {
      color: #171725;
      font-size: 18px;
      font-weight: 600;
      // background-image: -webkit-linear-gradient(left, #ffbf60, #ffe6d6);
      // -webkit-background-clip: text;
      // -webkit-text-fill-color: transparent;
    }

    .close {
      margin-left: 10px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      width: 30px;
      -webkit-app-region: no-drag;
    }

    .close:hover {
      border-radius: 5px;
      cursor: pointer;
      background-color: rgba(232, 17, 35, 0.9);
    }

    .btStyle {
      color: #fff;
      font-size: 18px;
      font-weight: 600px;
    }

    .yuanStyle {
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 5px;
      background-color: #9598a7;
    }

    .qieStyle {
      position: relative;
      margin-top: 50px;
    }

    .person1 {
      margin-top: 20px;
      width: 90%;
      margin-left: 50px;
      position: relative;

      .customizedRightX1 {
        width: 100%;
        height: 150px;
        background: linear-gradient(to right, #efecff, #ded1ff);
        border-radius: 8px;
        padding: 20px;
        margin-top: 10px;
        border: 1px solid #dcd3c5;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    .personStyle {
      width: 292px;
      height: 92px;
      background-image: url("@/assets/imgs/wenandi.png");
      border-radius: 20px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: space-around;
      cursor: pointer;
    }

    .personStyle2 {
      width: 280px;
      height: 80px;
      background-color: rgba(115, 115, 118, 0.7);
      border: solid #8b5ef5 1px;
      border-radius: 8px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: space-around;
      cursor: pointer;
      margin-top: 5px;
    }

    .personStyle12 {
      margin-left: 25px;
      margin-top: 35px;
      width: 100%;
    }

    .btn {
      width: 190px;
      padding: 6px 0;
      margin: 10px 0 10px 0;
      font-size: 12px;
      color: white;
      font-weight: 600;
      background-color: #8b5ef5;
      border-radius: 8px;
      border: 0px;
      outline: none;
      cursor: pointer;
      overflow: hidden;
      height: 38px;
    }

    .personWorld {
      font-size: 12px;
      color: #9598a7;
    }
  }
  .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #8b5ef5;
    border: 1px solid #8b5ef5;
  }

  .el-checkbox__inner:hover {
    border: 1px solid #8b5ef5;
  }

  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #8b5ef5;
    border: 1px solid #8b5ef5;
  }

  .customizedLefTop {
    width: 100%;
    height: 480px;
    background-color: #fbf9ff;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .wordJb {
    // color: rgba(194, 190, 240, 1);
    color: #a35d3c;
    font-size: 18px;
    font-weight: 600;
    // background-image: -webkit-linear-gradient(left, #f1e0d6, #c2bef0);
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
  }

  .customizedLefButton {
    width: 100%;
    height: 40px;
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .customizedRightT {
    width: 750px;
    height: 480px;
    margin-top: -10px;

    .customizedRightX {
      width: 100%;
      height: 150px;
      background-color: #ffe2c1;
      border-radius: 8px;
      padding: 20px;
      margin-top: 10px;
      margin-bottom: 20px;
    }

    .customizedRightX1 {
      width: 100%;
      height: 150px;
      background-color: #ffece5;
      border-radius: 8px;
      padding: 20px;
      margin-top: 10px;
      // border: 1px solid #dcd3c5;
      margin-bottom: 10px;
    }
    .customizedRightX2 {
      width: 100%;
      height: 150px;
      background-color: #9facff;
      border-radius: 8px;
      padding: 20px;
      margin-top: 10px;
      // border: 1px solid #dcd3c5;
      margin-bottom: 10px;
    }

    .customizedDing {
      display: flex;
      align-items: center;
      background-color: #ffffff;
      // background: linear-gradient(to right, #FFF1DC, #ffe2c1);
      box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.16);
      width: 236px;
      height: 33px;
      border-radius: 4px;
      padding: 0 5px;
    }
  }

  .customizedRightZ {
    width: 530px;
    height: 15px;
    // background-color: #fff;
    margin-top: 20px;
  }

  .customizedRightB {
    width: 100%;
    margin-top: 15px;
    // // margin-left: 200px;
    // text-align: center;
    display: flex;
    justify-content: center;
  }

  .but7 {
    position: relative;
    width: 130px;
    font-size: 14px;
    color: white;
    background-color: #8b5ef5;
    border-radius: 8px;
    line-height: 38px;
    cursor: pointer;
    height: 38px;
    text-align: center;
  }
  .but71 {
    position: relative;
    width: 130px;
    font-size: 14px;
    color: white;
    background-color: #939393;
    border-radius: 8px;
    line-height: 38px;
    cursor: pointer;
    height: 38px;
    text-align: center;
  }

  .but8 {
    position: relative;
    width: 130px;
    font-size: 14px;
    color: white;
    background-color: #c4adf9;
    border-radius: 8px;
    line-height: 38px;
    cursor: pointer;
    height: 38px;
    text-align: center;
  }
}

.close {
  margin-left: 10px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  width: 30px;
  -webkit-app-region: no-drag;
}

.close:hover {
  border-radius: 5px;
  cursor: pointer;
  background-color: rgba(232, 17, 35, 0.9);
}
</style>
