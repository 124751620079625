<template>
  <div class="home">
    <!-- 头部 -->
    <headComponent></headComponent>
    <div class="mainStyle">
      <div class="img-box">
        <img class="images" src="@/assets/imgs/flow-bg.png" alt="" />
      </div>
      <div class="mainStyle-box">
        <div class="left">
          <!-- <div class="title">Hello-Nvwa</div>
          <div class="conter">数字媒体短视频&直播客户端</div> -->
          <div>
            <img src="@/assets/imgs/client-left.png" alt="" />
          </div>
        </div>
        <div class="right">
          <img src="@/assets/imgs/client-right.png" alt="" />
        </div>
      </div>
    </div>

    <div v-if="indexA === 1" class="client-one">
      <div class="right-bg">
        <img src="@/assets/imgs/client-2-1.png" alt="" />
      </div>
      <div class="one-box">
        <div class="box-top">
          <div @click="indexA = 1" class="top-left">
            <div class="left-box">
              <div class="logo">
                <img src="@/assets/imgs/client-windows.png" alt="" />
              </div>
              <div class="title">Windows</div>
            </div>
          </div>
          <div @click="handelIndexA" class="top-right">
            <div class="right-box">
              <div class="logo">
                <img src="@/assets/imgs/client-android-fill.png" alt="" />
              </div>
              <div class="title">Android</div>
            </div>
          </div>
        </div>
        <div class="one-main">
          <div class="main-left">
            <div class="left-title">Hello Nvwa For Windows</div>
            <div class="left-main">仅适用于Windows 10/11（64位）操作系统</div>
            <div class="image">
              <img src="@/assets/imgs/package.png" alt="" />
            </div>
            <div @click="download" class="btn">客户端下载</div>
          </div>
          <div class="main-right">
            <img style="width: 100%" src="@/assets/imgs/client-main-1.png" alt="" />
          </div>
        </div>
      </div>
    </div>

    <div v-else class="client-two">
      <div class="right-bg">
        <img src="@/assets/imgs/client-2-1.png" alt="" />
      </div>
      <div class="one-box">
        <div class="box-top">
          <div @click="indexA = 1" class="top-left">
            <div class="left-box">
              <div class="logo">
                <img src="@/assets/imgs/client-windows-fill.png" alt="" />
              </div>
              <div class="title">Windows</div>
            </div>
          </div>
          <div @click="handelIndexA" class="top-right">
            <div class="right-box">
              <div class="logo">
                <img src="@/assets/imgs/client-android.png" alt="" />
              </div>
              <div class="title">Android</div>
            </div>
          </div>
        </div>
        <div class="one-main">
          <div class="main-left">
            <div class="left-title">Hello Nvwa For Android</div>
            <div class="left-main">适用于Android设备，轻松实现移动虚拟化直播</div>
            <div class="image">
              <div class="left">
                <div class="left-img" @click="gotoApp">
                  <!-- <img src="@/assets/imgs/client-weixin.png" alt="" /> -->
                  <div id="myQrCode" ref="myQrCode"></div>
                </div>
                <div class="left-text">扫码下载直播App</div>
              </div>
              <!-- <div class="right">
                <div class="right-img">
                  <img src="@/assets/imgs/client-weixin.png" alt="" />
                </div>
                <div class="right-text">扫码下载直播App</div>
              </div> -->
            </div>
          </div>
          <div class="main-right" style="position: relative; right: 70px; top: -50px">
            <img style="width: 100%" src="@/assets/imgs/client-main-2.png" alt="" />
          </div>
        </div>
      </div>
    </div>

    <div class="client-three">
      <div class="three-box">
        <div class="three-title">一站式多场景智能直播解决方案</div>
        <div class="three-text">
          支持Windows、Android等设备，多个直播平台矩阵式同步直播
        </div>
        <div class="three-main">
          <el-carousel :interval="5000" arrow="never">
            <el-carousel-item>
              <div class="main">
                <img src="@/assets/imgs/three-main-1.png" alt="" />
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="main"><img src="@/assets/imgs/three-main-2.png" alt="" /></div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>

    <footerComponent></footerComponent>
  </div>
</template>

<script>
import { getDownload, addDownload } from "@/api/login";
import headComponent from "@/views/component/headComponent.vue";
import footerComponent from "@/views/component/footerComponent.vue";
import QRCode from "qrcodejs2";
export default {
  name: "HomeView",
  components: { headComponent, footerComponent },
  data() {
    return {
      indexA: 1,
      packetUrl: "",
    };
  },
  computed: {},
  created() {
    window.scrollTo(0, 0);
  },
  mounted() {
    // this.packetUrl = "192.168.101.155:8080/#/help/androidApp";
    // this.packetUrl = "https://hello.yohelo.com/#/help/androidApp";
    this.packetUrl = process.env.VUE_APP_URL + "/#/help/androidApp";
  },
  methods: {
    handelIndexA() {
      this.indexA = 2;
      this.generateCode();
    },
    async download() {
      const res = await getDownload(1);
      const data = await addDownload("hello");
      console.log(res.data.data.packetUrl, "111111111111111111111111111111111");
      window.open(res.data.data.packetUrl);
    },
    generateCode() {
      setTimeout(() => {
        document.getElementById("myQrCode").innerHTML = ""; //每次生成的时候清空内容，否则会叠加，二维码背景色透明会一目了然
        try {
          const qrcode = new QRCode("myQrCode", {
            width: 120, //宽度
            height: 120, // 高度
            text: this.packetUrl, // 二维码内容
            // text:'https://www.baidu.com'
          });
        } catch (e) {
          console.log(e);
        }
      }, 100);
    },
    gotoApp() {
      // console.log(process.env.VUE_APP_URL);
      this.$router.push({ path: "/help/androidApp" });
      // window.open(this.packetUrl);
    },
  },
};
</script>

<style lang="less" scoped>
.home {
  display: flex;
  flex-direction: column;
  // overflow-y: auto; /* 超出部分显示垂直滚动条 */
  flex-grow: 1;
  .mainStyle {
    margin-top: 64px;
    flex-grow: 1;
    min-width: 1200px;
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    position: relative;
    .img-box {
      position: absolute;
      height: 100%;
      width: 100%;
      .images {
        position: absolute;
        top: 0;
        width: 100%;
        left: 0;
        height: 100%;
        background-repeat: no-repeat;
        background-position: top;
        background-size: cover;
        object-fit: cover;
      }
    }
    .mainStyle-box {
      z-index: 1;
      width: 1200px;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
      }
    }
  }
  .client-one {
    min-width: 1200px;
    height: 658px;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    position: relative;
    .right-bg {
      position: absolute;
      right: 0px;
    }
    .one-box {
      width: 1200px;
      height: 100%;
      .box-top {
        width: 100%;
        height: 125px;
        display: flex;
        justify-content: center;
        .top-left {
          width: 207px;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          border-bottom: 2px solid #8b5ef5;
          padding-bottom: 27px;
          cursor: pointer;
          .left-box {
            display: flex;
            align-items: center;
            .logo {
              margin-top: 1px;
              margin-right: 10px;
            }
            .title {
              font-family: Microsoft YaHei, Microsoft YaHei;
              font-weight: bold;
              font-size: 20px;
              color: #8b5ef5;
              line-height: 13px;
            }
          }
        }
        .top-right {
          width: 207px;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          padding-bottom: 27px;
          cursor: pointer;
          .right-box {
            display: flex;
            align-items: center;
            .logo {
              margin-top: 1px;
              margin-right: 10px;
            }
            .title {
              font-family: Microsoft YaHei, Microsoft YaHei;
              font-weight: bold;
              font-size: 20px;
              color: #243042;
              line-height: 13px;
            }
          }
        }
      }
      .one-main {
        display: flex;
        justify-content: space-between;
        margin-top: 80px;
        .main-left {
          width: 50%;
          height: 425px;
          display: flex;
          flex-direction: column;
          align-items: center;
          .left-title {
            font-family: Microsoft YaHei, Microsoft YaHei;
            font-weight: 600;
            font-size: 34px;
            color: #243042;
            margin-top: 10px;
          }
          .left-main {
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 400;
            font-size: 16px;
            color: #243042;
            line-height: 50px;
          }
          .image {
            margin: 10px 0;
          }
          .btn {
            width: 205px;
            height: 64px;
            background: #8b5ef5;
            box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.16);
            border-radius: 8px 8px 8px 8px;
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 500;
            font-size: 20px;
            color: #ffffff;
            line-height: 52px;
            text-align: center;
          }
        }
        .main-right {
          z-index: 1;
          width: 509px;
          height: 306px;
        }
      }
    }
  }
  .client-two {
    min-width: 1200px;
    height: 658px;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    position: relative;
    .right-bg {
      position: absolute;
      right: 0px;
    }
    .one-box {
      width: 1200px;
      height: 100%;
      .box-top {
        width: 100%;
        height: 125px;
        display: flex;
        justify-content: center;
        .top-left {
          width: 207px;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          padding-bottom: 27px;
          cursor: pointer;
          .left-box {
            display: flex;
            align-items: center;
            .logo {
              margin-top: 1px;
              margin-right: 10px;
            }
            .title {
              font-family: Microsoft YaHei, Microsoft YaHei;
              font-weight: bold;
              font-size: 20px;
              color: #243042;
              line-height: 13px;
            }
          }
        }
        .top-right {
          width: 207px;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          padding-bottom: 27px;
          border-bottom: 2px solid #8b5ef5;
          cursor: pointer;
          .right-box {
            display: flex;
            align-items: center;
            .logo {
              margin-top: 1px;
              margin-right: 10px;
            }
            .title {
              font-family: Microsoft YaHei, Microsoft YaHei;
              font-weight: bold;
              font-size: 20px;
              color: #8b5ef5;
              line-height: 13px;
            }
          }
        }
      }
      .one-main {
        display: flex;
        justify-content: space-between;
        margin-top: 80px;
        .main-left {
          width: 50%;
          height: 425px;
          display: flex;
          flex-direction: column;
          align-items: center;
          .left-title {
            font-family: Microsoft YaHei, Microsoft YaHei;
            font-weight: 600;
            font-size: 34px;
            color: #243042;
            margin-top: 10px;
          }
          .left-main {
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 400;
            font-size: 16px;
            color: #243042;
            line-height: 50px;
          }
          .image {
            display: flex;
            justify-content: center;
            .left {
              //margin-right: 60px;
              .left-img {
                width: 140px;
                height: 140px;
                background: #ffffff;
                box-shadow: 0px 3px 6px 1px rgba(101, 101, 101, 0.2);
                border-radius: 8px 8px 8px 8px;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              .left-text {
                font-family: Source Han Sans SC, Source Han Sans SC;
                font-weight: bold;
                font-size: 18px;
                color: #243042;
                line-height: 41px;
                text-align: center;
              }
            }
            .right {
              .right-img {
                width: 140px;
                height: 140px;
                background: #ffffff;
                box-shadow: 0px 3px 6px 1px rgba(101, 101, 101, 0.2);
                border-radius: 8px 8px 8px 8px;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              .right-text {
                font-family: Source Han Sans SC, Source Han Sans SC;
                font-weight: bold;
                font-size: 18px;
                color: #243042;
                line-height: 41px;
                text-align: center;
              }
            }
          }
        }
        .main-right {
          z-index: 1;
          width: 368px;
          height: 356px;
        }
      }
    }
  }
  .client-three {
    min-width: 1200px;
    height: 740px;
    background-image: url("@/assets/imgs/client-3-bg.png");
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    position: relative;
    .three-box {
      width: 1200px;
      height: 100%;
      .three-title {
        font-family: Microsoft YaHei, Microsoft YaHei;
        font-weight: bold;
        font-size: 34px;
        color: #243042;
        text-align: center;
        margin-top: 45px;
      }
      .three-text {
        font-family: Source Han Sans SC, Source Han Sans SC;
        font-weight: 400;
        font-size: 20px;
        color: #8f93a9;
        text-align: center;
        margin-top: 15px;
      }
      .three-main {
        display: flex;
        justify-content: center;
        width: 100%;
        // .main {
        //       // margin-top: 61px;
        //       width: 940px;
        //       height: 480px;
        //       background: #d1d1d1;
        //       box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
        //       border-radius: 12px 12px 12px 12px;
        //       opacity: 0.9;
        //     }

        ::v-deep .el-carousel__container {
          margin-top: 61px;
          width: 940px;
          height: 480px;
          .el-carousel__item {
            background-color: yellow;
            border-radius: 12px 12px 12px 12px;
          }
        }
        ::v-deep .is-active {
          .el-carousel__button {
            width: 14px !important;
            height: 14px !important;
            background: #8b5ef5 !important;
            border-radius: 25px !important;
          }
        }
        ::v-deep .el-carousel__button {
          width: 34px !important;
          height: 14px !important;
          background: #707070;
          border-radius: 7px 7px 7px 7px;
          border: 1px solid #707070 !important;
        }
      }
    }
  }
}
</style>
