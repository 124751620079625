<template>
  <div class="teamFiles">
    <audio
      style="display: none"
      ref="audioPlayer"
      @play="handlePlay"
      @pause="handlePause"
      @ended="handleNext"
    ></audio>
    <div class="top">
      <div class="left">
        <div class="left-item" v-for="(item, index) in routerList">
          <span
            @click="teamHandel(index, item.isShow)"
            :style="item.isShow ? 'cursor: pointer;color:#92929D;' : ''"
            >{{ item.name }}</span
          >
          <span v-show="item.isShow" style="margin: 0 5px">/</span>
        </div>
      </div>
      <div class="right">
        <div class="one">存储空间：</div>
        <div class="two">
          <span style="color: #8b5ef5">{{ useCloudSpaceSize }} MB</span>
          <span>/{{ cloudSpaceSize }}GB</span>
        </div>
        <div class="three">云空间管理</div>
        <div class="four" @click="expansion">去扩容></div>
      </div>
    </div>
    <div class="background-box">
      <el-form ref="form" :model="form" label-width="20px">
        <el-form-item label="">
          <el-input
            style="width: 300px"
            v-model="form.materialName"
            placeholder="输入关键词进行搜索"
          >
            <template #suffix>
              <i
                style="margin-right: 10px"
                class="el-icon-search"
                @click="handleGetTeamUser"
              ></i>
            </template>
          </el-input>
          <el-input v-show="false"></el-input>
        </el-form-item>
      </el-form>
      <div style="display: flex" v-show="isShow == 0">
        <div class="teamStyle" @click="getTeamUser(4, '数字人')">
          <div>
            <img src="@/assets/imgs/wenjianjia.png" alt="" />
          </div>
          <div>数字人</div>
        </div>
        <div class="teamStyle" @click="getTeamUser(5, '数字语音')">
          <div>
            <img src="@/assets/imgs/shuziyuyintubiao.png" alt="" />
          </div>
          <div>数字语音</div>
        </div>
        <div class="teamStyle" @click="getTeamUser(1, '视频')">
          <div>
            <img src="@/assets/imgs/shipintubiao.png" alt="" />
          </div>
          <div>视频</div>
        </div>
        <div class="teamStyle" @click="getTeamUser(2, '音频')">
          <div>
            <img src="@/assets/imgs/yinpintubiao.png" alt="" />
          </div>
          <div>音频</div>
        </div>
        <div class="teamStyle" @click="getTeamUser(3, '图片')">
          <div>
            <img src="@/assets/imgs/tupiantubiao.png" alt="" />
          </div>
          <div>图片</div>
        </div>
      </div>

      <div class="search-for" v-show="isShow == 6">
        <div class="search-item" v-for="(list, index) in teamList" :key="list.index">
          <div
            v-if="list.materialType == 1"
            class="button-img"
            style="position: relative"
          >
            <div class="main-box">
              <video
                id="videL"
                style="background-color: #eeeeee"
                :src="list.materialUrl"
                width="180px"
                height="135px"
                controls
                controlslist="nodownload"
              ></video>
            </div>
            <!-- <div class="main-chakan">
              <img  src="@/assets/imgs/chakan.png" alt="" />
            </div> -->
            <div class="main-icon">
              <div style="margin-right: 8px" class="icon"></div>
              <div class="icon">
                <img
                  @click="downloadHandel(list.materialUrl)"
                  src="@/assets/imgs/download-Video.png"
                  alt=""
                />
              </div>
            </div>
            <div class="button-main">
              <div v-show="list.id != renameId" class="main-title">
                {{ list.materialName }}
              </div>
              <div v-show="list.id != renameId" class="main-time">
                {{ list.createTime }}
              </div>
              <div class="main-input" v-show="list.id == renameId">
                <el-input
                  style="height: 18px"
                  v-model="renameName"
                  placeholder="请输入"
                  @change="renameEvn(list.id, list.materialId, list)"
                ></el-input>
              </div>
            </div>
            <div class="box-dot" @click.stop="roundList(list.id)">
              <div><img src="@/assets/imgs/dot.png" alt="" /></div>
            </div>
            <div class="round1" v-show="list.id == showId" @blur="handleBlur">
              <div
                v-if="isTeamAdmin == 1"
                class="round1-name-two"
                @click.stop="rename(list.id, list.materialId, list)"
              >
                重命名
              </div>
              <div v-else class="round1-name-two-fill">重命名</div>
              <div class="round1-name-two" @click="downloadHandel(list.materialUrl)">
                下载
              </div>
              <div
                v-if="isTeamAdmin == 1"
                class="round1-name-two"
                @click="delMaterial(1, list.id)"
              >
                删除
              </div>
              <div v-else class="round1-name-two-fill">删除</div>
            </div>
          </div>

          <div
            v-if="list.materialType == 2"
            class="button-img"
            style="position: relative"
          >
            <div class="main-box">
              <img src="@/assets/imgs/ylbj.png" alt="" width="180px" height="135px" />
            </div>
            <div @click="playPause(list)" class="main-chakan">
              <img v-if="audioId !== list.id" src="@/assets/imgs/bofang.png" alt="" />
              <img v-else src="@/assets/imgs/bofangzhon.gif" alt="" />
            </div>
            <div class="main-icon">
              <div style="margin-right: 8px" class="icon"></div>
              <div class="icon">
                <img
                  @click="downloadHandel(list.materialUrl)"
                  src="@/assets/imgs/download-Video.png"
                  alt=""
                />
              </div>
            </div>
            <div class="button-main">
              <div v-show="list.id != renameId" class="main-title">
                {{ list.materialName }}
              </div>
              <div v-show="list.id != renameId" class="main-time">
                {{ list.createTime }}
              </div>
              <div class="main-input" v-show="list.id == renameId">
                <el-input
                  style="width: 250px; height: 18px"
                  v-model="renameName"
                  placeholder="请输入"
                  @change="renameEvn(list.id, list.materialId, list)"
                ></el-input>
              </div>
            </div>
            <div class="box-dot" @click.stop="roundList(list.id)">
              <div><img src="@/assets/imgs/dot.png" alt="" /></div>
            </div>
            <div class="round1" v-show="list.id == showId" @blur="handleBlur">
              <div
                v-if="isTeamAdmin == 1"
                class="round1-name-two"
                @click.stop="rename(list.id, list.materialId, list)"
              >
                重命名
              </div>
              <div v-else class="round1-name-two-fill">重命名</div>
              <div class="round1-name-two" @click="downloadHandel(list.materialUrl)">
                下载
              </div>
              <div
                v-if="isTeamAdmin == 1"
                class="round1-name-two"
                @click="delMaterial(1, list.id)"
              >
                删除
              </div>
              <div v-else class="round1-name-two-fill">删除</div>
            </div>
          </div>

          <div
            v-if="list.materialType == 3"
            class="button-img"
            style="position: relative"
          >
            <div class="main-box">
              <div
                style="
                  width: 180px;
                  height: 135px;
                  align-items: center;
                  position: relative;
                  display: flex;
                  align-items: center;
                "
              >
                <img
                  :src="list.materialUrl"
                  alt=""
                  style="max-width: 100%; max-height: 100%; display: block; margin: auto"
                />
              </div>
            </div>
            <div class="main-icon">
              <div style="margin-right: 8px" class="icon"></div>
              <div class="icon">
                <img
                  @click="downloadHandel(list.materialUrl)"
                  src="@/assets/imgs/download-Video.png"
                  alt=""
                />
              </div>
            </div>
            <div class="button-main">
              <div v-show="list.id != renameId" class="main-title">
                {{ list.materialName }}
              </div>
              <div v-show="list.id != renameId" class="main-time">
                {{ list.createTime }}
              </div>
              <div class="main-input" v-show="list.id == renameId">
                <el-input
                  style="width: 250px; height: 18px"
                  v-model="renameName"
                  placeholder="请输入"
                  @change="renameEvn(list.id, list.materialId, list)"
                ></el-input>
              </div>
            </div>
            <div class="box-dot" @click.stop="roundList(list.id)">
              <div><img src="@/assets/imgs/dot.png" alt="" /></div>
            </div>
            <div class="round1" v-show="list.id == showId" @blur="handleBlur">
              <div
                v-if="isTeamAdmin == 1"
                class="round1-name-two"
                @click.stop="rename(list.id, list.materialId, list)"
              >
                重命名
              </div>
              <div v-else class="round1-name-two-fill">重命名</div>
              <div class="round1-name-two" @click="downloadHandel(list.materialUrl)">
                下载
              </div>
              <div
                v-if="isTeamAdmin == 1"
                class="round1-name-two"
                @click="delMaterial(1, list.id)"
              >
                删除
              </div>
              <div v-else class="round1-name-two-fill">删除</div>
            </div>
          </div>

          <div
            v-if="list.materialType == 4"
            class="button-img"
            style="position: relative"
          >
            <div class="main-box">
              <video
                id="videL2"
                style="background-color: #eeeeee"
                :src="list.materialUrl"
                width="180px"
                height="135px"
                controls
                controlslist="nodownload"
              ></video>
            </div>
            <div class="main-icon">
              <div class="icon">
                <img
                  @click="downloadHandel(list.materialUrl)"
                  src="@/assets/imgs/download-Video.png"
                  alt=""
                />
              </div>
            </div>
            <div class="button-main">
              <div v-show="list.id != renameId" class="main-title">
                {{ list.materialName }}
              </div>
              <div v-show="list.id != renameId" class="main-time">
                {{ list.createTime }}
              </div>
              <div class="main-input" v-show="list.id == renameId">
                <el-input
                  style="width: 250px; height: 18px"
                  v-model="renameName"
                  placeholder="请输入"
                  @change="renameEvn(list.id, list.materialId, list)"
                ></el-input>
              </div>
            </div>
            <div class="box-dot" @click.stop="roundList(list.id)">
              <div><img src="@/assets/imgs/dot.png" alt="" /></div>
            </div>
            <div class="round1" v-show="list.id == showId" @blur="handleBlur">
              <div
                v-if="isTeamAdmin == 1"
                class="round1-name-two"
                @click.stop="rename(list.id, list.materialId, list)"
              >
                重命名
              </div>
              <div v-else class="round1-name-two-fill">重命名</div>
              <div class="round1-name-two" @click="downloadHandel(list.materialUrl)">
                下载
              </div>
              <div
                v-if="isTeamAdmin == 1"
                class="round1-name-two"
                @click="delMaterial(1, list.id)"
              >
                删除
              </div>
              <div v-else class="round1-name-two-fill">删除</div>
            </div>
          </div>

          <div
            v-if="list.materialType == 5"
            class="button-img"
            style="position: relative"
          >
            <div class="main-box">
              <img
                src="@/assets/imgs/yuyinbeijingtu1.png"
                alt=""
                width="180px"
                height="135px"
              />
            </div>
            <div @click="viewVideo(list)" class="main-chakan"></div>
            <div class="main-icon">
              <div style="margin-right: 8px" class="icon">
                <img @click="viewVideo(list)" src="@/assets/imgs/view-Video.png" alt="" />
              </div>
              <div class="icon">
                <img
                  @click="downloadHandel(list.materialUrl)"
                  src="@/assets/imgs/download-Video.png"
                  alt=""
                />
              </div>
            </div>
            <div class="button-main">
              <div v-show="list.id != renameId" class="main-title">
                {{ list.materialName }}
              </div>
              <div v-show="list.id != renameId" class="main-time">
                {{ list.createTime }}
              </div>
              <div class="main-input" v-show="list.id == renameId">
                <el-input
                  style="width: 250px; height: 18px"
                  v-model="renameName"
                  placeholder="请输入"
                  @change="renameEvn(list.id, list.materialId, list)"
                ></el-input>
              </div>
            </div>
            <div class="box-dot" @click.stop="roundList(list.id)">
              <div><img src="@/assets/imgs/dot.png" alt="" /></div>
            </div>
            <div class="round1" v-show="list.id == showId" @blur="handleBlur">
              <div
                v-if="isTeamAdmin == 1"
                class="round1-name-two"
                @click.stop="rename(list.id, list.materialId, list)"
              >
                重命名
              </div>
              <div v-else class="round1-name-two-fill">重命名</div>
              <div class="round1-name-two" @click="downloadHandel(list.materialUrl)">
                下载
              </div>
              <div
                v-if="isTeamAdmin == 1"
                class="round1-name-two"
                @click="delMaterial(1, list.id)"
              >
                删除
              </div>
              <div v-else class="round1-name-two-fill">删除</div>
            </div>
          </div>
        </div>
      </div>

      <el-dialog title="数字语音详情" :visible.sync="dialogVisible" width="650px">
        <div class="dialog-box">
          <div class="title">{{ dialogTitle }}</div>
          <div class="conter" v-html="dialogUrl">111</div>
        </div>
      </el-dialog>
    </div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible1"
      width="1061px"
      :before-close="handleClose"
    >
      <div class="userStyle-refueling1">
        <!-- <div style="display: flex; justify-content: space-between; -webkit-app-region: drag;">
          <div style="width: 20px; height: 20px;">
          </div>
          <p style="color: #fff;font-weight: 600;font-size: 18px;margin-left: 30px;"></p>
          <div style="display: flex; margin-top: 15px; margin-right: 10px;">
            <div class="close" @click="closeBtn">
              <img src="@/assets/imgs/guanbi.png" alt="">
            </div>
          </div>
        </div> -->
        <div
          class="qieStyle1"
          style="
            background-color: #ded1ff;
            width: 780px;
            height: 56px;
            margin-left: 100px;
            border-radius: 50px;
          "
        >
          <div
            :class="spaceId == item.id ? 'spaceListStyle1' : 'spaceListStyle'"
            v-for="(item, index) in spaceList"
            :key="item.index"
            @click="getSpace(item.dictKey, item.id)"
          >
            {{ item.dictValue }}
          </div>
        </div>
        <div class="person10">
          <div style="display: flex; justify-content: space-evenly">
            <div
              :class="item.id == listId ? 'personStyle2' : 'personStyle'"
              v-for="(item, index) in spaceBuyList"
              :key="item.index"
              @click="buyRefueling(item, 2)"
            >
              <div style="font-size: 16px; font-weight: 600">{{ item.timeType }}个月</div>
              <div style="display: flex; align-items: center">
                <div>
                  <span style="font-size: 12px; font-weight: 600">￥</span>
                  <span style="font-size: 24px">{{ item.price }}</span>
                </div>
                <div>
                  <div
                    style="
                      font-size: 12px;
                      text-decoration: line-through;
                      margin-left: 10px;
                    "
                  >
                    ￥{{ item.price * 2 }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="personStyle1">
            <div style="display: flex; align-items: center; margin-top: 10px">
              <div
                style="
                  width: 160px;
                  height: 160px;
                  background-color: #fff;
                  border-radius: 11px;
                "
              >
                <div v-show="paymentShow1 == 1">
                  <img
                    src="@/assets/imgs/zferweima.png"
                    alt=""
                    width="100%"
                    height="100%"
                  />
                </div>
                <div
                  class="qrcode1Style"
                  style=""
                  id="qrcode1"
                  v-show="paymentShow1 == 2"
                ></div>
                <div v-show="paymentShow1 == 3">
                  <img src="@/assets/imgs/cg.png" alt="" />
                  <div style="font-size: 14px; color: #262626; margin-left: 60px">
                    支付成功
                  </div>
                </div>
              </div>

              <div>
                <div
                  style="
                    margin-left: 20px;
                    display: flex;
                    align-items: baseline;
                    margin-bottom: 15px;
                    width: 500px;
                  "
                >
                  <div>
                    <img
                      src="@/assets/imgs/wxsmzf.png"
                      alt=""
                      style="margin-right: 8px"
                    />
                  </div>
                  <div style="font-size: 16px; color: #262626">微信扫码支付，实付：</div>
                  <div style="font-size: 41px; color: #e33f2d">￥{{ money }}</div>
                </div>
                <div
                  v-show="buySueShow == 1"
                  style="
                    margin-left: 20px;
                    font-size: 22px;
                    background-color: #8b5ef5;
                    width: 200px;
                    height: 60px;
                    border-radius: 8px;
                    color: #fff;
                    line-height: 60px;
                    text-align: center;
                    cursor: pointer;
                  "
                  @click="buySue"
                >
                  立即购买
                </div>
                <div
                  v-show="buySueShow == 2"
                  style="
                    margin-left: 20px;
                    font-size: 22px;
                    background-color: #8b5ef5;
                    width: 200px;
                    height: 60px;
                    border-radius: 8px;
                    color: #fff;
                    line-height: 60px;
                    text-align: center;
                    cursor: not-allowed;
                  "
                >
                  请扫码支付
                </div>
                <div style="margin-left: 20px; margin-top: 15px">
                  <span style="color: #a6a6a6">支付即视为同意</span>
                  <span style="color: #8b5ef5; cursor: pointer" @click="pdfFile"
                    >《用户协议条例》</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  teamUserEquity,
  getToPay,
  getsSaveId,
  getSuccess,
  spaceRefuelBagList,
  getDictList,
} from "@/api/information";
import { getMaterialList, getMaterialDel, getPut } from "@/api/cloudSpace";
import QRCode from "qrcodejs2";
// import { teamUser } from "@/api/cloudSpace"
import Cookies from "js-cookie";
export default {
  name: "centre",
  data() {
    return {
      teamList: [],
      form: {
        name: "",
      },
      current: 1,
      limit: 20,
      isShow: 0,
      showId: "",
      type: "",
      renameName: "",
      renameId: "",
      cloudSpaceSize: "",
      useCloudSpaceSize: "",
      routerName: "",
      routerList: [{ name: "团队文件", isShow: false }],
      dialogVisible: false,
      dialogType: "",
      dialogTitle: "",
      dialogUrl: "",
      title: "",
      dialogVisible1: false,
      goodsList: [],
      type: "",
      goodId: "",
      id: "",
      paymentShow: 1,
      money: "",
      paymentShow1: 1,
      qrcode: null,
      qrcode1: null,
      tmeri: null,
      tmeri1: null,
      value: "",
      isShowEr: "",
      isShowEr1: "",
      listId: "",
      erweimaData: "",
      spaceList: [],
      spaceId: "",
      spaceBuyList: [],
      valNmu: "",
      buySueShow: 1,
      teamId: localStorage.getItem("teamId"),
      isPlaying: false,
      player: null,
      audioId: "",
      typeShow: "",
      isTeamAdmin: "",
    };
  },
  created() {
    this.isTeamAdmin = localStorage.getItem("isTeamAdmin");
    // this.getTeamUser()
    this.userEquity();
    this.questionType();
  },
  mounted() {
    document.addEventListener("click", this.handleBlur);
    this.player = this.$refs.audioPlayer;
  },
  methods: {
    playPause(item) {
      this.audioId = item.id;
      this.player.src = item.materialUrl;
      if (this.isPlaying) {
        this.audioId = "";
        this.isPlaying = false;
        this.player.pause();
      } else {
        setTimeout(() => {
          this.player.play();
        }, 1000);
      }
    },
    handlePlay() {
      this.isPlaying = true;
    },
    handlePause() {
      this.audioId = "";
      this.isPlaying = false;
    },
    handleNext() {
      this.audioId = "";
      this.isPlaying = false;
    },
    viewVideo(item) {
      this.dialogVisible = true;
      this.dialogTitle = item.materialName;
      this.dialogUrl = JSON.parse(item.voiceText).voiceHtml;
    },
    expansion() {
      this.$router.push("/cloudSpace").catch((error) => error);
      // this.dialogVisible1 = true
    },
    pdfFile() {},
    async questionType() {
      const data = {
        code: "cloud_space_size",
      };
      const res = await getDictList(data);
      this.spaceList = res.data.data[0].children;
      this.getSpace(this.spaceList[0].dictKey, this.spaceList[0].id);
    },
    async getSpace(val, id) {
      this.money = 0.0;
      this.spaceId = id;
      const res = await spaceRefuelBagList(val);
      this.spaceBuyList = res.data.data;
      this.buyRefueling(this.spaceBuyList[0], val);
    },
    async buyRefueling(list, val) {
      if (!list) {
      } else {
        this.listId = list.id;
        this.money = list.price;
      }
      this.paymentShow1 = 1;
      // this.paymentShow = 1
      this.buySueShow = 1;
      this.valNmu = val;

      // this.payment(val)
    },
    async buySue() {
      this.buySueShow = 2;
      const goodId = {
        goodIds: this.listId,
        teamId: this.teamId,
      };
      const res = await getsSaveId(goodId);
      this.id = res.data.data;
      this.payment(this.valNmu);
    },
    async payment(val) {
      const idData = {
        orderId: this.id,
      };
      if (val == 1) {
        this.tmeri1 = null;
        this.tmeri = null;
        const codeList = document.getElementById("qrcode");
        codeList.innerHTML = "";
        const res = await getToPay(idData);

        this.qrcode = new QRCode("qrcode", {
          width: 140, // 宽
          height: 140, // 高
          text: res.data.data, // 二维码内容,也可以直接放接口获取的数据
          qrCodeSuccess: function (canvas) {
            // 将二维码转为图片
          },
        });

        this.paymentShow = 2;
        if (res.data.code == 200) {
          this.tmeri = setInterval(() => {
            this.successBuy(1);
          }, 3000);
        }
      } else {
        this.tmeri = null;
        this.tmeri1 = null;
        const codeList = document.getElementById("qrcode1");
        codeList.innerHTML = "";
        const res = await getToPay(idData);
        this.qrcode1 = new QRCode("qrcode1", {
          width: 140, // 宽
          height: 140, // 高
          text: res.data.data, // 二维码内容,也可以直接放接口获取的数据
          render: "canvas", // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
          qrCodeSuccess: function (canvas) {
            // 将二维码转为图片
          },
        });

        this.paymentShow1 = 2;
        if (res.data.code == 200) {
          this.tmeri1 = setInterval(() => {
            this.successBuy(2);
          }, 3000);
        }
      }
    },
    async successBuy(val) {
      const orderId = this.id;
      if (val == 1) {
        this.tmeri1 = null;
      } else {
        this.tmeri = null;
      }
      const res = await getSuccess(orderId);
      this.successData == res.data.data;
      if (res.data.data == "NOTPAY") {
      } else if (res.data.data == "SUCCESS") {
        this.tmeri = null;
        this.tmeri1 = null;
        this.paymentShow1 = 3;
        this.paymentShow = 3;
        this.dialogVisible1 = false;
        this.userEquity();
      }
    },
    refuelingBag() {},
    closeBtn() {},
    handleClose(done) {
      done();
      // const codeList = document.getElementById('qrcode');
      // codeList.innerHTML = '';
      // const codeList1 = document.getElementById('qrcode1');
      // codeList1.innerHTML = '';
      // const codeList2 = document.getElementById('qrcode2');
      // codeList2.innerHTML = '';
    },
    async userEquity() {
      const a = {
        teamId: localStorage.getItem("teamId"),
        userId: localStorage.getItem("userId"),
      };
      const res = await teamUserEquity(a);
      this.cloudSpaceSize = res.data.data.cloudSpaceSize;
      this.useCloudSpaceSize = res.data.data.useCloudSpaceSize;
    },
    async handleGetTeamUser() {
      this.isShow = 6;
      const material = {
        materialType: this.type,
        current: this.current,
        size: this.limit,
        relevancyType: 3,
        materialName: this.form.materialName,
        teamId: localStorage.getItem("teamId"),
      };
      const res = await getMaterialList(material);
      this.teamList = res.data.data.records;
      this.teamList.forEach((item) => {
        item.materialUrl =
          window.localStorage.getItem("OssPrefix") + "/" + item.materialUrl;
        item.createTime = item.createTime.substr(0, 10);
      });
    },
    async getTeamUser(val, name) {
      this.typeShow = val;
      this.routerName = name;
      this.routerList[0].isShow = true;
      this.routerList.push({ name: name, isShow: false });
      this.type = val;
      this.isShow = 6;
      const material = {
        current: this.current,
        size: this.limit,
        materialType: this.type,
        relevancyType: 3,
        teamId: localStorage.getItem("teamId"),
      };
      const res = await getMaterialList(material);
      this.teamList = res.data.data.records;
      this.teamList.forEach((item) => {
        item.materialUrl =
          window.localStorage.getItem("OssPrefix") + "/" + item.materialUrl;
        item.createTime = item.createTime.substr(0, 10);
      });
    },
    handleBlur() {
      // 对失去焦点的监听
      this.showId = 1;
      // this.renameId = 1
    },
    roundList(id) {
      this.showId = id;
    },
    async rename(id, materialId, item) {
      this.renameName = item.materialName;
      this.renameId = id;
      this.showId = 1;
    },
    async renameEvn(id, materialId, item) {
      item.materialName = this.renameName;
      const liveData = {
        materialName: this.renameName,
        materialId: materialId,
      };
      const res = await getPut(id, liveData);
      this.renameName = "";
      this.renameId = 1;
    },
    downloadHandel(url) {
      this.showId = 1;
      window.open(url);
    },
    async delMaterial(val, id) {
      this.showId = 1;
      const res = await getMaterialDel(id);
      this.$message({
        type: "success",
        message: "删除成功!",
      });
      this.getTeamUser(val, this.routerName);
    },
    teamHandel(inx, isShow) {
      if (!this.$refs.audioPlayer) {
      } else {
        this.$refs.audioPlayer.pause();
      }

      var videL = document.getElementById("videL");
      if (!videL) {
      } else {
        videL.pause();
      }

      var videL2 = document.getElementById("videL2");
      if (!videL2) {
      } else {
        videL2.pause();
      }
      if (this.type === "") {
        isShow = true;
      }
      if (isShow) {
        this.isShow = 0;
        this.type = "";
        this.routerList = [{ name: "团队文件", isShow: false }];
      }
    },
  },
};
</script>

<style lang="less" scoped>
/*video默认全屏按钮*/
video::-webkit-media-controls-fullscreen-button {
  display: none !important;
}

/*video默认aduio音量按钮*/
video::-webkit-media-controls-mute-button {
  display: none !important;
}

/*video默认setting按钮*/
video::-internal-media-controls-overflow-button {
  display: none !important;
}

/*隐藏controls中的音量控制*/
video::-webkit-media-controls-volume-slider,
video::-webkit-media-controls-mute-button {
  //隐藏android端video标签自带的音量调节按钮
  display: none;
}
::v-deep .el-dialog__header {
  background: linear-gradient(to bottom, #f2ecff, #ffffff);
}
.userStyle-refueling1 {
  // background-color: #1a1a1a;
  width: 1020px;
  height: 630px;
  // background-image: url('@/assets/imgs/beijing2.png');
  // background-size: 100% 100%;
  // background-attachment: fixed;
  overflow: hidden;
  user-select: none;
  // border: 1px solid #515152;

  .qrcode1Style {
    border-radius: 11px;
    overflow: hidden;
    border: 10px solid #fff !important;
    //
  }

  #qrcode1 {
    img {
      border: 10px solid #fff !important;
    }
  }
  .close {
    margin-left: 10px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    width: 30px;
    -webkit-app-region: no-drag;
  }

  .close:hover {
    border-radius: 5px;
    cursor: pointer;
    background-color: rgba(232, 17, 35, 0.9);
  }

  .btStyle {
    color: #fff;
    font-size: 18px;
    font-weight: 600px;
  }

  .yuanStyle {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 5px;
    background-color: #9598a7;
  }

  .qieStyle1 {
    position: relative;
    margin-top: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    .spaceListStyle {
      width: 240px;
      height: 52px;
      text-align: center;
      line-height: 52px;
      border-radius: 30px;
      background-color: #ded1ff;
      color: #fff;
      font-size: 20px;
      font-weight: 600;
      margin: 0 10px;
      cursor: pointer;
    }

    .spaceListStyle1 {
      width: 240px;
      height: 52px;
      text-align: center;
      line-height: 52px;
      border-radius: 30px;
      background-color: #8b5ef5;
      color: #fff;
      font-size: 20px;
      font-weight: 600;
      margin: 0 10px;
      cursor: pointer;
    }
  }

  .person10 {
    margin-top: 50px;
    width: 90%;
    margin-left: 50px;
    position: relative;
  }

  .personStyle {
    width: 260px;
    height: 80px;
    background: linear-gradient(to right, #efecff, #ded1ff);
    // background-image: url('@/assets/imgs/soacebj.png');
    border-radius: 8px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    cursor: pointer;
    color: #171725;
  }

  .personStyle2 {
    width: 260px;
    height: 80px;
    background-color: #8b5ef5;
    border: solid #8b5ef5 1px;
    border-radius: 8px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    cursor: pointer;
    color: #fff;
    // margin-top: 5px;
  }

  .personStyle1 {
    margin-left: 25px;
    margin-top: 90px;
  }

  .btn {
    width: 190px;
    padding: 6px 0;
    margin: 10px 0 10px 0;
    font-size: 12px;
    color: white;
    font-weight: 600;
    background-color: #8b5ef5;
    border-radius: 8px;
    border: 0px;
    outline: none;
    cursor: pointer;
    overflow: hidden;
    height: 38px;
  }

  .personWorld {
    font-size: 12px;
    color: #9598a7;
  }
}

.teamFiles {
  height: 90vh;
  .dialog-box {
    width: 100%;
    height: 380px;
    border-radius: 6px;
    border: 1px solid #e2e2ea;
    overflow-y: auto;
    .title {
      text-align: center;
      font-family: Source Han Sans SC, Source Han Sans SC;
      font-weight: bold;
      font-size: 18px;
      color: #171725;
      margin-top: 15px;
      margin-bottom: 15px;
    }
    .conter {
      padding-left: 12px;
      padding-right: 12px;
    }
  }
  .top {
    display: flex;
    justify-content: space-between;
    .left {
      font-family: Source Han Sans SC, Source Han Sans SC;
      font-weight: bold;
      font-size: 22px;
      color: #444444;
      line-height: 24px;
      margin-bottom: 10px;
      display: flex;
      .left-item {
      }
    }
    .right {
      display: flex;
      .one {
        margin-right: 5px;
        font-family: Source Han Sans SC, Source Han Sans SC;
        font-weight: 500;
        font-size: 16px;
      }
      .two {
        margin-right: 15px;
        font-family: Source Han Sans SC, Source Han Sans SC;
        font-weight: 500;
        font-size: 16px;
      }
      .three {
        margin-right: 10px;
        font-family: Source Han Sans SC, Source Han Sans SC;
        font-weight: 500;
        font-size: 16px;
      }
      .four {
        margin-right: 5px;
        font-family: Source Han Sans SC, Source Han Sans SC;
        font-weight: 500;
        font-size: 16px;
        color: #ce8831;
        cursor: pointer;
      }
    }
  }
  .background-box {
    background-color: #ffffff;
    height: 85vh;
  }
}
::v-deep .el-input__inner {
  height: 30px;
  border-radius: 20px;
}

.teamStyle {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95px;
  margin-left: 20px;
  cursor: pointer;
}

.search-for {
  display: flex;
  width: 95%;
  height: 155px;
  flex-wrap: wrap;
  justify-content: flex-start;
  .search-item {
    .button-img {
      width: 100%;
      height: 100%;
      margin-top: 20px;
      margin-right: 15px;
      border-radius: 8px;
      position: relative;
      cursor: pointer;
      .main-box {
        margin-left: 20px;
        width: 180px;
      }
      .main-chakan {
        position: absolute;
        margin-left: 20px;
        top: 0px;
        width: 180px;
        height: 135px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 50px;
          height: 50px;
        }
      }
      .main-icon {
        position: absolute;
        right: 23px;
        bottom: 52px;
        display: flex;
        .icon {
          img {
            width: 16px;
            height: 16px;
          }
        }
      }

      .round1 {
        width: 100px;
        height: 90px;
        background-color: #ffffff;
        border-radius: 8px;
        position: absolute;
        z-index: 999;
        top: 38px;
        left: 170px;
        overflow: hidden;
        background: #ffffff;
        box-shadow: 0px 1px 8px 1px rgba(0, 0, 0, 0.12);
        border-radius: 4px 4px 4px 4px;

        .round1-name {
          color: #000000;
          font-size: 10px;
          cursor: pointer;
        }

        .round1-name-two {
          color: #000000;
          font-size: 10px;
          padding-left: 10px;
          cursor: pointer;
          height: 30px;
          line-height: 30px;
        }
        .round1-name-two-fill {
          color: #ccc;
          font-size: 10px;
          padding-left: 10px;
          cursor: pointer;
          height: 30px;
          line-height: 30px;
        }
        .round-name:hover {
          background-color: #a0a0a0;
        }

        .round1-name-two:hover {
          background-color: #a0a0a0;
        }
      }

      .button-main {
        width: 180px;
        display: flex;
        justify-content: space-between;
        margin-top: 5px;
        margin-left: 20px;
        .main-title {
          font-family: Source Han Sans SC, Source Han Sans SC;
          font-weight: 400;
          font-size: 14px;
          color: #444444;
          width: 60px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .main-time {
          font-family: Source Han Sans SC, Source Han Sans SC;
          font-weight: 400;
          font-size: 14px;
          color: #444444;
        }
        .main-input {
          position: relative;
          left: 35px;
          top: 5px;
        }

        ::v-deep .el-input__inner {
          height: 30px;
          border-radius: 0px;
          width: 100px;
          border-radius: 10px;
        }
      }
      .box-dot {
        position: absolute;
        top: 5px;
        right: 30px;
        cursor: pointer;
        width: 10px;
        padding-left: 5px;
        height: 50px;
        z-index: 10;
      }
    }
  }
}
</style>
